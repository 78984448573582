import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { RadioButton, RadioButtonList } from '@webteam/radio-button';
import { LoadingIcon, SuccessIcon } from '@webteam/icons';
import { ErrorMessage } from '../../common/error-message/error-message';
import { ThirdMachineCode, ThirdMachineOptions } from '../../../api/models/server';
import api from '../../../api/routes';

import styles from './third-machine-switcher.module.scss';
import { ThirdMachinePopup } from './third-machine-popup';

interface ThirdMachineOption {
  title: string;
  description: string;
  code: ThirdMachineCode;
}

const allocateOption: ThirdMachineOption = {
  code: ThirdMachineCode.Allocate,
  description: 'allocate one more license',
  title: 'Allocate New',
};

const kickOption: ThirdMachineOption = {
  code: ThirdMachineCode.Kick,
  description: 'revoke every license from the oldest machine',
  title: 'Take Oldest Out',
};

const errorOption: ThirdMachineOption = {
  code: ThirdMachineCode.Prohibited,
  description: 'restrict using more than 2 machines by a single user',
  title: 'Prohibited',
};

const options = new Map<string, ThirdMachineOption>();
options.set(errorOption.code, errorOption);
options.set(kickOption.code, kickOption);
options.set(allocateOption.code, allocateOption);

const SaveProgress: FC<{ loading: boolean }> = ({ loading }) => {
  return loading ? <LoadingIcon className={styles.icon} size="xs" /> : <SuccessIcon className={styles.icon} size="xs" />;
};
export const ThirdMachineSwitcher: FC<{ data: ThirdMachineOptions }> = ({ data }) => {
  const [visibleProgressOption, setVisibleOptionProgress] = useState<ThirdMachineCode | undefined>(undefined);
  const [loadingOption, setLoadingOption] = useState<ThirdMachineCode | undefined>(undefined);
  const [disabled, disableRadios] = useState(false);
  const [popupOpen, togglePopup] = useState(false);
  const [saveFailure, setSaveFailure] = useState('');

  let savedIconTimout: NodeJS.Timeout | undefined;

  const setLoading = (loading?: ThirdMachineCode, code?: ThirdMachineCode) => {
    setVisibleOptionProgress(code);
    setLoadingOption(loading);
  };

  const resetLoading = () => setLoading();

  const update = (option: ThirdMachineOption | undefined) => {
    if (!option) return;
    disableRadios(true);
    setLoading(option.code, option.code);
    setSaveFailure('');
    if (savedIconTimout) clearTimeout(savedIconTimout);
    api.server.thirdMachineOptions
      .update(option.code)
      .then(() => {
        setLoading(undefined, option.code);
        savedIconTimout = setTimeout(() => {
          resetLoading();
          if (savedIconTimout) clearTimeout(savedIconTimout);
        }, 450);
        return savedIconTimout;
      })
      .catch(() => {
        resetLoading();
        setSaveFailure(`Failed to save new settings.'`);
      })
      .finally(() => disableRadios(false));
  };

  if (!data) return <LoadingIcon />;

  return (
    <>
      <p className={classNames('wt-text-2 wt-text-2_hardness_hard')}>A single user can use the same license on up to 2 machines.</p>
      <p className={classNames('wt-text-2 wt-text-2_hardness_hard')}>Define the behavior in case of using the 3rd machine</p>
      <p className={classNames('wt-text-2', 'wt-text-2_hardness_hard')}>
        <span className={classNames('wt-link')} onClick={() => togglePopup(true)}>
          Compare modes
        </span>
      </p>
      <ThirdMachinePopup open={popupOpen} onClose={() => togglePopup(false)} />
      <div className={'wt-offset-top-12'}>
        <RadioButtonList mode={'nude'} size={'s'} defaultValue={data.current} onChange={(value) => update(options.get(value))}>
          {data.available.map((code) => {
            const visible = visibleProgressOption === code;
            const option = options.get(code);
            if (!option) return <></>;
            return (
              <RadioButton key={code} value={code} disabled={disabled}>
                <span className={classNames('wt-text-2', 'wt-text-2_hardness_hard')}>
                  <b>{option.title}</b> - <NoBreakItemAndText text={option.description} item={visible ? <SaveProgress loading={loadingOption === code} /> : <div className={styles.emptyIcon} />} />
                </span>
              </RadioButton>
            );
          })}
        </RadioButtonList>
        <ErrorMessage message={saveFailure} />
      </div>
    </>
  );
};

const NoBreakItemAndText: FC<{ text: string; item: JSX.Element }> = ({ text, item }): JSX.Element => {
  const splitText = text.split(' ');
  const lastWord = splitText.pop();
  if (splitText.length === 0) return <span className={styles.noWrap}>{lastWord}</span>;
  return (
    <>
      {splitText.join(' ')}{' '}
      <span className={styles.noWrap}>
        {lastWord}
        {item}
      </span>
    </>
  );
};
