import { BADButton, BADPopup } from '@bad/components';
import styles from './api-token.module.scss';
import { Row } from '@webteam/layout';
import { CloseIcon } from '@webteam/icons';
import classNames from 'classnames';

interface Props {
  id: string;
  isOpen: boolean;
  onProceed: () => any;
  onAbort: () => any;
}

export const RegenerateTokenPopup = ({ isOpen, onProceed, onAbort }: Props) => {
  return (
    <BADPopup
      primaryActionLabel={<span>Generate new token</span>}
      closeActionLabel={<span>Cancel</span>}
      alignButton="end"
      buttonSize="m"
      isReversedButtons={true}
      className={classNames(styles.popup, styles.popupSmall)}
      formikConfig={{
        initialValues: {},
        onSubmit: onProceed,
      }}
      isOpen={isOpen}
      onRequestClose={onAbort}
      header={
        <Row justify="between" alignItems="center">
          <span className="wt-col-inline">Generate a new API access token?</span>
          <BADButton className="wt-col-inline" mode="nude" icon={<CloseIcon className={styles.closeButton} />} onClick={onAbort} />
        </Row>
      }
      children={() => (
        <>
          <p className="wt-row">Generating a new token invalidates the current one.</p>
          <p className="wt-row wt-offset-top-16">You’ll need to update the token in any scripts or applications that use the API.</p>
        </>
      )}
    />
  );
};
