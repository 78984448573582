import React, { FC, useMemo } from 'react';
import { Cell } from 'react-table';
import classNames from 'classnames';
import { BADTable } from '@bad/components';
import { HistoryTicketDto, LicenseTicketDto, ProductTicketDto } from '../../../api/models/users';
import { MenuDropdown } from '../../../components/dashboard/users-table/menu-dropdown';
import { ModeContext } from '../../../context';
import { LicenseLabel, ProductLabel } from '../../../components/common/license-table/license-and-product-cell';
import { FromTicketLabel, LastUsageTicketLabel } from '../../../components/common/license-table/dates-cell';
import { getTimezone } from '../../../common';
import { LicenseServerMode } from '../../../api/models/server';

import styles from './users-history.module.scss';

interface UserHistoryTableRow extends ProductTicketDto {
  licenseName?: string;
  licenseTicket?: LicenseTicketDto;
}

const formatToTableRow = (data: LicenseTicketDto[]) =>
  data
    .map((el) => {
      const result: UserHistoryTableRow[] = el.productTickets;
      result[0].licenseTicket = el;
      return result;
    })
    .reduce((previousValue, currentValue) => previousValue.concat(currentValue));

export const UserHistoryTable: FC<{
  username: string;
  data: HistoryTicketDto;
  revalidate: () => Promise<HistoryTicketDto | undefined>;
}> = ({ username, data, revalidate }) => {
  const tableData = useMemo(() => {
    const licenceNameToLicenseTickets = Object.entries(data);
    if (licenceNameToLicenseTickets.length === 0) return [];
    return licenceNameToLicenseTickets
      .map((el) => {
        const result = formatToTableRow(el[1]);
        result[0].licenseName = el[0];
        return result;
      })
      .reduce((previousValue, currentValue) => previousValue.concat(currentValue));
  }, [data]);

  const columnsFn = (mode: LicenseServerMode) => {
    if (mode === LicenseServerMode.NonFloating) return [licenseColumn, lastSeenColumn, userProductColumn, hostnameColumn, ipColumn, revokeColumn];
    if (mode === LicenseServerMode.Floating) return [licenseColumn, allocationFromTo, userProductColumn, hostnameColumn, ipColumn, revokeColumn];
    return [];
  };

  const lastSeenColumn = {
    id: 'lastSeen',
    Header: () => <span>Last seen (GMT {getTimezone()})</span>,
    columns: [
      {
        id: 'lastSeen',
        accessor: 'dates',
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          return <LastUsageTicketLabel lastUsage={value.lastUsage} release={value.release} />;
        },
      },
    ],
  };

  const allocationFromTo = {
    id: 'allocation',
    Header: () => <span>Allocation from-to (GMT {getTimezone()})</span>,
    columns: [
      {
        accessor: 'dates',
        id: 'allocationFrom',
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          return <FromTicketLabel from={value.from} />;
        },
      },
      {
        id: 'allocationTo',
        accessor: 'dates',
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          return <LastUsageTicketLabel lastUsage={value.lastUsage} release={value.release} />;
        },
      },
    ],
  };

  const licenseColumn = {
    id: 'license',
    Header: 'Allocated product / license',
    columns: [
      {
        id: 'license',
        accessor: 'licenseName',
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          return value ? <LicenseLabel name={value} /> : <></>;
        },
      },
    ],
  };

  const userProductColumn = {
    id: 'userProduct',
    Header: 'User product',
    columns: [
      {
        id: 'userProduct',
        accessor: 'product',
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          return <ProductLabel name={value.name} />;
        },
      },
    ],
  };

  const hostnameColumn = {
    id: 'hostname',
    Header: 'Hostname',
    columns: [
      {
        id: 'hostname',
        accessor: 'hostname',
      },
    ],
  };

  const ipColumn = {
    id: 'ip',
    Header: 'IP',
    columns: [
      {
        id: 'ip',
        accessor: 'ip',
      },
    ],
  };

  const revokeColumn = {
    id: 'revoke',
    Header: '',
    columns: [
      {
        id: 'revoke',
        accessor: 'licenseTicket',
        maxWidth: 10,
        minWidth: 10,
        Cell: ({ value }: Cell<UserHistoryTableRow>) => {
          const isLicenseTicketOpen = value && value.id != null;
          return isLicenseTicketOpen ? <MenuDropdown username={username} licenseTickets={[value]} revalidate={revalidate} /> : <span className={styles.emptyMenu} />;
        },
      },
    ],
  };

  return (
    <ModeContext.Consumer>
      {(value: LicenseServerMode) => (
        <BADTable
          className={classNames('wt-text-3', styles.alignMenu, styles.hideSubHeader, styles.table)}
          offsetTableSize={0}
          trClassName={(el) => el.original.licenseName && styles.rowBorder}
          size="m"
          tableData={tableData}
          columnsFn={() => columnsFn(value)}
        />
      )}
    </ModeContext.Consumer>
  );
};
