import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import 'react-vis/dist/style.css';

import PortalSwitch from './switches/portal-switch';
import { cleanUpPending } from './common/pending-changes';

const App: FC = () => {
  cleanUpPending();
  const context = process.env.REACT_APP_CONTEXT_PATH || '';
  const APP_PATH = process.env.REACT_APP_APP_PATH ?? '';
  return (
    <BrowserRouter basename={context + APP_PATH!!}>
      <PortalSwitch />
    </BrowserRouter>
  );
};

export default App;
