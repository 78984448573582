import { FC } from 'react';
import classNames from 'classnames';
import { LoadingIcon } from '@webteam/icons';
import dayjs from 'dayjs';
import { ErrorMessage } from '../../common/error-message/error-message';
import { ServerPlanDto } from '../../../api/models/server';
import { Problem } from '../../../api/models/problem';

export const PlanInfo: FC<{ data: ServerPlanDto | undefined; error: Problem | undefined }> = ({ data, error }) => {
  if (!data && !error) return <LoadingIcon />;
  if (error || !data) return <ErrorMessage message={`Failed to load. Try reloading the page.`} />;
  let validity = data.validUntil ? ' until ' + dayjs(data.validUntil).format('MMMM D, YYYY') : '';
  return (
    <span className={classNames('wt-text-2', 'wt-text-2_hardness_hard')}>
      {data.name}
      {validity}
    </span>
  );
};
