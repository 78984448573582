import React, { FC, useState } from 'react';
import classnames from 'classnames';
import * as yup from 'yup';
import { FormikErrors, FormikHelpers, FormikTouched } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { useSm } from '@webteam/breakpoints';
import { List, ListItem } from '@webteam/list';
import { Col, Row } from '@webteam/layout';
import { BADPopup } from '@bad/components';
import { Header } from './header';
import { Footer } from './footer';
import { DefineUsers } from './popup-pages/define-users';
import { LimitProducts } from './popup-pages/limit-products';
import { CheckRule } from './popup-pages/chek-rule';
import { routes } from '../../../../../../shared-interface';
import {
  BaseAccessRuleDto,
  BasicRuleProduct,
  BasicRuleSubject,
  IdentifiableProduct,
  IdentifiableSubject,
  ProductAccess,
  TestRuleData,
  TestRuleResult,
  UpdateAccessRuleDto,
} from '../../../../../../api/models/server/rules';
import api from '../../../../../../api/routes';

import styles from './add-or-edit-rule.module.scss';
import { Problem } from '../../../../../../api/models/problem';

export enum PopupPage {
  Users,
  Products,
  Test,
}

export enum PopupState {
  Add = 'Add',
  Copy = 'Copy',
  Edit = 'Edit',
  Test = 'Test',
}

export interface FormikRuleData {
  name: string;
  subjects: BasicRuleSubject[];
  products: BasicRuleProduct[];
  testSubjects?: BasicRuleSubject;
  testProducts?: { label: string; value: string };
  testData?: { response: TestRuleResult; data: TestRuleData };
}

export const AddOrEditRulePopup: FC<{ state: PopupState; onRequestClose: () => void }> = ({ state, onRequestClose }) => {
  const isSm = useSm();
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const [activePage, setActivePage] = useState(state === PopupState.Test ? PopupPage.Test : PopupPage.Users);
  const activeStyle = (el: PopupPage) => classnames({ [styles.active]: el === activePage }, styles.stepLabel);
  const rules = api.server.rules.useRules();
  const rule = state !== PopupState.Add && rules.data?.find((el) => el.id === +id);
  const [enabled, toggleRule] = useState(rule ? (state === PopupState.Copy ? true : rule.enabled) : true);
  const [error, setError] = useState<string>();
  const access = ProductAccess.Permitted;
  const ruleName: string | undefined = rule ? (state === PopupState.Copy ? `${rule.name} (Copy)` : rule.name) : undefined;
  const initialValues: FormikRuleData = {
    name: ruleName ?? '',
    subjects: rule
      ? rule.subjects.map((el) => {
          return { name: el.name, subjectType: el.subjectType, id: el.id };
        })
      : [],
    products: rule
      ? rule.products.map((el) => {
          return { name: el.name, code: el.code, type: el.type, id: el.id };
        })
      : [],
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .matches(/.*[^ ].*/, 'Please enter a valid name.')
      .max(100, 'Maximum value is 100.')
      .required('This field is required.'),
    subjects: yup.array().min(1, 'This field is required.'),
    products: yup.array().min(1, 'This field is required.'),
  });

  const onSuccess = () => {
    rules.mutate().then(() => onRequestClose());
  };

  const onError = (error: Problem, setSubmitting: (isSubmitting: boolean) => void) => {
    setSubmitting(false);
    setError(error.detail);
  };

  const onSubmit = (value: FormikRuleData, { setSubmitting }: FormikHelpers<FormikRuleData>) => {
    if (rule && (state === PopupState.Edit || state === PopupState.Test)) {
      setError('');
      const data: UpdateAccessRuleDto = {
        name: value.name.trim(),
        enabled,
        access,
        subjectsToAdd: value.subjects.filter((el) => !rule.subjects.find((subject) => subject.name === el.name && subject.subjectType === el.subjectType)),
        subjectsToDelete: rule.subjects.filter((subject) => !value.subjects.find((el) => subject.name === el.name && subject.subjectType === el.subjectType)),
        productsToAdd: value.products.filter((el) => !rule.products.find((subject) => subject.type === el.type && subject.code === el.code)),
        productsToDelete: rule.products.filter((subject) => !value.products.find((el) => subject.type === el.type && subject.code === el.code)),
      };
      api.server.rules
        .updateRule(rule.id, data)
        .then(onSuccess)
        .catch((error) => onError(error, setSubmitting));
    } else {
      const data: BaseAccessRuleDto<BasicRuleSubject, BasicRuleProduct> = {
        name: value.name.trim(),
        enabled,
        access,
        subjects: value.subjects,
        products: value.products,
      };
      api.server.rules
        .addRule(data)
        .then(onSuccess)
        .catch((error) => onError(error, setSubmitting));
    }
  };

  const onDelete =
    rule && (state === PopupState.Test || state === PopupState.Edit)
      ? () => {
          navigate(routes().admin.rules.tabs.list.delete(rule.id));
        }
      : undefined;

  const getErrorPage = (errors: FormikErrors<FormikRuleData>) => {
    if (errors.name || errors.subjects) return PopupPage.Users;
    if (errors.products) return PopupPage.Products;
  };

  const getError = (touched: boolean = false, errors?: string) => {
    if (touched && errors) return errors;
  };

  const getFormErrors = (touched: FormikTouched<FormikRuleData>, errors: FormikErrors<FormikRuleData>) => {
    return {
      name: getError(touched.name, errors.name as string),
      subjects: getError(!!touched.subjects, errors.subjects as string),
      products: getError(!!touched.products, errors.products as string),
    };
  };

  if ((rule && id) || !id)
    return (
      <BADPopup
        size="l"
        isOpen={true}
        onRequestClose={onRequestClose}
        formikConfig={{
          initialValues,
          onSubmit,
          validationSchema,
          validateOnBlur: false,
        }}
        header={<Header toggleRule={toggleRule} name={ruleName} enabled={enabled} onRequestCloseAndClearChanges={onRequestClose} />}
        className={classnames(styles.header, styles.popup)}
        footer={({ isSubmitting, submitForm, values, validateForm }) => {
          return (
            <Footer
              error={error}
              isSubmitting={isSubmitting}
              onSubmit={() => {
                validateForm(values).then((err) => {
                  setActivePage(getErrorPage(err) ?? activePage);
                  submitForm();
                });
              }}
              onDelete={onDelete}
              activePage={activePage}
              setActivePage={setActivePage}
              onRequestCloseAndClearChanges={onRequestClose}
            />
          );
        }}
      >
        {({ values, setFieldValue, errors, touched, setFieldTouched }) => {
          const currentRule = {
            name: values.name,
            enabled,
            access,
            subjects: values.subjects,
            products: values.products,
          };
          const formErrors = getFormErrors(touched, errors);
          return (
            <Row className={styles.content}>
              {!isSm && (
                <Col span={3} className={classnames(styles.list, 'wt-col-md-4')}>
                  <List>
                    <ListItem className={activeStyle(PopupPage.Users)} onClick={() => setActivePage(PopupPage.Users)}>
                      1. Specify Users{(formErrors.name || formErrors.subjects) && <div className={styles.errorLabel} />}
                    </ListItem>
                    <ListItem className={activeStyle(PopupPage.Products)} onClick={() => setActivePage(PopupPage.Products)}>
                      2. Specify Products{formErrors.products && <div className={styles.errorLabel} />}
                    </ListItem>
                    <ListItem className={activeStyle(PopupPage.Test)} onClick={() => setActivePage(PopupPage.Test)}>
                      3. Test Rule
                    </ListItem>
                  </List>
                </Col>
              )}
              <Col span="auto-fill">
                <ContentSwitcher
                  errors={formErrors}
                  ruleId={id}
                  rule={currentRule}
                  activePage={activePage}
                  value={values}
                  setFieldValue={(field: string, value: any) => {
                    setFieldTouched(field);
                    setFieldValue(field, value);
                  }}
                />
              </Col>
            </Row>
          );
        }}
      </BADPopup>
    );
  return <></>;
};

const ContentSwitcher: FC<{
  activePage: PopupPage;
  value: FormikRuleData;
  errors: FormikErrors<FormikRuleData>;
  setFieldValue: (field: string, value: any) => void;
  ruleId?: string;
  rule: BaseAccessRuleDto<BasicRuleSubject | IdentifiableSubject, BasicRuleProduct | IdentifiableProduct>;
}> = ({ errors, activePage, value, setFieldValue, ruleId, rule }) => {
  switch (activePage) {
    case PopupPage.Users:
      return <DefineUsers errors={errors} list={value.subjects} setList={(value) => setFieldValue('subjects', value)} />;
    case PopupPage.Products:
      return <LimitProducts errors={errors} list={value.products} setList={(value) => setFieldValue('products', value)} />;
    case PopupPage.Test:
      return <CheckRule value={value} setFieldValue={setFieldValue} ruleId={ruleId} rule={rule} />;
  }
};
