import server from './server';
import { useLogout } from './sessions';
import licenses from './licenses';
import users from './users';
import ticket from './tickets';
import report from './report';
import { settings } from './settings';
import { apiToken } from './settings/api-token';

let api = {
  licenses,
  users,
  server,
  sessions: {
    useLogout,
  },
  ticket,
  report,
  apiToken,
  settings,
};

export default api;
