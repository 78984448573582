import { AxiosResponse } from 'axios';
import dayjs from 'dayjs';

export const downloadFileByResponse = (response: AxiosResponse) => {
  const { data, headers } = response;
  const href = URL.createObjectURL(new Blob([data]));
  let groups = headers['content-disposition'].match(/.*filename="?([^"]*)"?/);
  const fileName = groups?.[1] ?? 'report_' + dayjs();
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode?.removeChild(link);
  URL.revokeObjectURL(href);
};
