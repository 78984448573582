import React, { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Col, Row } from '@webteam/layout';
import { UserHistoryTable } from './table';
import { ContentSkeleton } from '../../../components/common/skeleton/content-skeleton';
import { ErrorPage } from '../../../components/common/error-page';
import api from '../../../api/routes';
import { routes } from '../../../shared-interface';
import { StyledBreadcrumbs } from '../../../components/common/breadcrumbs';

export const UserHistoryPage: FC = () => {
  const { userId } = useParams() as { userId: string };
  const location = useLocation();
  const usersHistory = api.users.useUsersHistory(userId);

  //FIXME: @ejller
  const breadcrumbsItemWithoutCurrent =
    // @ts-ignore
    location.state && location.state.breadcrumbsItem && location.state.breadcrumbsItem.length > 0 ? location.state.breadcrumbsItem : [['Dashboard', routes().admin.dashboard.tabs.products]];
  // @ts-ignore
  const username = (location.state && location.state.username) ?? '';
  const breadcrumbsItem = breadcrumbsItemWithoutCurrent.concat([[`${username} history`]]);

  const loading = !usersHistory.data && !usersHistory.error;
  if (loading) return <ContentSkeleton />;
  if (usersHistory.error || !usersHistory.data || isEmpty(usersHistory.data)) return <ErrorPage message={usersHistory.error?.title} detail={usersHistory.error?.detail} />;

  return (
    <>
      <Row className="wt-offset-top-12">
        <Col span="auto-fill">
          <StyledBreadcrumbs items={breadcrumbsItem} isReactRouterLink={true} />
        </Col>
      </Row>
      <Row className="wt-offset-top-24" alignItems="center">
        <Col span="auto-fill">
          <h3 className="wt-h3 title"> {username} Week History</h3>
        </Col>
      </Row>
      <Row>
        <Col span={12} className="wt-offset-top-48">
          <UserHistoryTable username={username} data={usersHistory.data ?? {}} revalidate={usersHistory.mutate} />
        </Col>
      </Row>
    </>
  );
};

const isEmpty = (obj: Object) => Object.keys(obj).length === 0;
