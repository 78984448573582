import { client } from '../../../../shared-interface';
import { defaultRoute } from '../index';
import { ThirdMachineCode, ThirdMachineOptions } from '../../../models/server';

export const update = (code: ThirdMachineCode) => {
  const newOptions: ThirdMachineOptions = {
    available: [],
    current: code,
  };
  return client().put(defaultRoute + '/third-machine-options', newOptions);
};
export const thirdMachineOptions = {
  update,
};
