import React, { FC, useMemo } from 'react';
import cn from 'classnames';
import { Outlet, useNavigate } from 'react-router-dom';
import { BADButton } from '@bad/components';
import { Col, Row } from '@webteam/layout';
import Checkbox from '@webteam/checkbox';
import { RuleTable } from './table';
import { routes } from '../../../../shared-interface';
import { DeleteRulePopup, SettingsRulePopup, SwitchRulePopup } from './popups';
import { TestRulePopup } from './popups/test-rule';
import { ErrorPage } from '../../../../components/common/error-page';
import { AddOrEditRulePopup, PopupState } from './popups/add-or-edit-rule';
import api from '../../../../api/routes';

import styles from './list-of-rules.module.scss';

export const ListOfRulesTab = () => {
  const navigate = useNavigate();
  const rules = api.server.rules.useRules();
  const numberOfActiveRules = useMemo(() => (rules.data ? rules.data.filter((el) => el.enabled).length : 0), [rules.data]);

  if (rules.error || !rules.data) return <ErrorPage message={rules.error?.title} detail={rules.error?.detail} />;

  return (
    <div className="wt-offset-top-24">
      <Row>
        <Col span="inline" className="wt-col-md-12">
          <h2 className="wt-h2">
            {rules.data && rules.data.length > 0 ? rules.data.length : 'No'} Rule{rules.data.length !== 1 && 's'}
          </h2>
          <h3 className={cn(styles.subtitle, `wt-h3`)}>{numberOfActiveRules > 0 ? numberOfActiveRules : 'No'} Active</h3>
        </Col>
        <RuleSettingsCheckbox ruleCount={rules.data ? rules.data.length : 0} />
      </Row>
      <Row>
        <Col span="auto-fill" className="wt-offset-top-24">
          {rules.data.length > 0 ? (
            <RuleTable
              onSwitchRule={(id) => {
                navigate(routes().admin.rules.tabs.list.switch(id));
              }}
              onEditRule={(id) => {
                navigate(routes().admin.rules.tabs.list.edit(id));
              }}
              onTestRule={(id) => {
                navigate(routes().admin.rules.tabs.list.test(id));
              }}
              onDeleteRule={(id) => {
                navigate(routes().admin.rules.tabs.list.delete(id));
              }}
              onCopyRule={(id) => {
                navigate(routes().admin.rules.tabs.list.copy(id));
              }}
              data={rules.data}
            />
          ) : (
            <span className="wt-text-1 wt-text-1_hardness_average">You haven't added any rules yet.</span>
          )}
        </Col>
      </Row>
      <Outlet />
    </div>
  );
};

export const ListOfRulesTabButtons = () => {
  const navigate = useNavigate();
  const rules = api.server.rules.useRules();
  if (rules.error) return <></>;
  return (
    <>
      <Col span="inline" className="wt-offset-top-md-24">
        <BADButton
          onClick={() => {
            navigate(routes().admin.rules.tabs.list.testAll);
          }}
          mode="outline"
        >
          Test Rules
        </BADButton>
      </Col>
      <Col span="inline" className="wt-offset-top-md-24">
        <BADButton
          onClick={() => {
            navigate(routes().admin.rules.tabs.list.add);
          }}
        >
          Add Rule
        </BADButton>
      </Col>
    </>
  );
};

const RuleSettingsCheckbox: FC<{ ruleCount: number }> = ({ ruleCount }) => {
  const navigate = useNavigate();
  const ruleSettings = api.server.rules.useRuleSettings();

  return ruleSettings.data ? (
    <Col
      span="auto-fill"
      className={cn(
        styles.unruledUsersCheckbox,
        'wt-col-md-12',
        'wt-offset-top-md-12',
        ruleSettings.data.permitUnruledUsers ? styles.unruledUsersCheckboxEnabled : styles.unruledUsersCheckboxDisabled,
      )}
    >
      <Row>
        <Col span="auto-fill">
          <Checkbox
            checked={ruleSettings.data.permitUnruledUsers}
            onChange={() => {
              navigate(routes().admin.rules.tabs.list.settings);
            }}
          >
            <span className="wt-text-2 wt-text-2_hardness_hard">Allow users that are not mentioned in the rules to get licenses</span>
          </Checkbox>
        </Col>
      </Row>
      <Row className="wt-offset-top-12">
        <Col span="auto-fill" className="wt-text-3 wt-text-3_hardness_hard">
          {ruleCount > 0 ? (
            <>
              <span className={styles.boldText}>Users mentioned in the rules</span> can only get licenses that are permitted by these rules. <span className={styles.boldText}>Other users</span>{' '}
              {ruleSettings.data.permitUnruledUsers ? <> can get licenses without restrictions.</> : <> can’t get licenses.</>}
            </>
          ) : (
            <>
              <span className={styles.boldText}>All users</span>
              {ruleSettings.data.permitUnruledUsers ? <> can get licenses without restrictions</> : <> are prohibited from getting licenses</>}
            </>
          )}
        </Col>
      </Row>
    </Col>
  ) : (
    <></>
  );
};

export const SettingsRulesPopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <SettingsRulePopup onRequestClose={onRequestClose} />;
};

export const TestAllRulesPopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <TestRulePopup onRequestClose={onRequestClose} />;
};

export const AddRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <AddOrEditRulePopup state={PopupState.Add} onRequestClose={onRequestClose} />;
};

export const CopyRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <AddOrEditRulePopup state={PopupState.Copy} onRequestClose={onRequestClose} />;
};

export const EditRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <AddOrEditRulePopup state={PopupState.Edit} onRequestClose={onRequestClose} />;
};

export const TestRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <AddOrEditRulePopup state={PopupState.Test} onRequestClose={onRequestClose} />;
};

export const DeleteRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <DeleteRulePopup onRequestClose={onRequestClose} />;
};

export const SwitchRulePopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.list.path);
  return <SwitchRulePopup onRequestClose={onRequestClose} />;
};
