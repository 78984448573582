import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Col, Row } from '@webteam/layout';
import { BADButton } from '@bad/components';
import { PrioritiesTable } from './table';
import { routes } from '../../../../shared-interface';
import { DeletePrioritiesPopup } from './popups';
import { EditPrioritizedUsersPopup } from './popups/edit-prioritized-users';
import { ErrorPage } from '../../../../components/common/error-page';
import api from '../../../../api/routes';

import styles from './priorities.module.scss';

export const PrioritiesTab = () => {
  const navigate = useNavigate();
  const priorities = api.server.rules.priorities.usePriorities();

  if (priorities.error || !priorities.data) return <ErrorPage message={priorities.error?.title} detail={priorities.error?.detail} />;

  return (
    <div className="wt-offset-top-24">
      <h2 className="wt-h2">
        {priorities.data.length > 0 ? priorities.data.length : 'No'} Prioritized User{priorities.data.length !== 1 && 's'}
      </h2>
      <Row className="wt-offset-top-48 wt-row-md_direction_column-reverse">
        <Col span={6} className="wt-col-md-12  wt-offset-top-md-24">
          {priorities.data.length > 0 ? (
            <PrioritiesTable
              data={priorities.data ?? []}
              onDeletePriorities={(id: number) => {
                navigate(routes().admin.rules.tabs.priorities.delete(id));
              }}
              onEditPriorities={() => {
                navigate(routes().admin.rules.tabs.priorities.edit);
              }}
            />
          ) : (
            <span className="wt-text-1 wt-text-1_hardness_average">You haven't prioritized any users yet.</span>
          )}
        </Col>
        <Col offsetLeft={1} span={5} className="wt-col-md-12 wt-offset-left-md-0">
          <p className="wt-text-1 wt-text-1_hardness_hard">
            <span className={styles.boldText}>Add users to the priority list to ensure they can always get licenses.</span>
          </p>
          <p className={'wt-text-1 wt-text-1_hardness_hard wt-offset-top-24'}>
            If a prioritized user requests a license when all of them are taken, License Vault revokes and transfers a license from a non-prioritized user.{' '}
            <a className="wt-link" target={'_blank'} href={'https://jb.gg/lv-rules-prioritized'} rel={'noreferrer'}>
              Learn more
            </a>
          </p>
        </Col>
      </Row>
      <Outlet />
    </div>
  );
};

export const PrioritiesTabButtons = () => {
  const navigate = useNavigate();
  const priorities = api.server.rules.priorities.usePriorities();
  if (priorities.error) return <></>;
  return (
    <Col span="inline" className="wt-offset-top-md-24">
      <BADButton
        onClick={() => {
          navigate(routes().admin.rules.tabs.priorities.edit);
        }}
      >
        Edit Priorities
      </BADButton>
    </Col>
  );
};

export const EditPrioritiesPopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.priorities.path);
  return <EditPrioritizedUsersPopup onRequestClose={onRequestClose} />;
};

export const DeletePrioritiesPopupTab = () => {
  const navigate = useNavigate();
  const onRequestClose = () => navigate(routes().admin.rules.tabs.priorities.path);
  return <DeletePrioritiesPopup onRequestClose={onRequestClose} />;
};
