import { LicenseUsageStatPeriod } from '../../../../../../api/models/licenses';
import dayjs from 'dayjs';

export const getChartRectWidth = (period: LicenseUsageStatPeriod) => {
  switch (period) {
    case LicenseUsageStatPeriod.TwentyFourHours:
    case LicenseUsageStatPeriod.ThirtyDays:
      return 0.55;
    case LicenseUsageStatPeriod.SixtyDays:
      return 0.65;
    case LicenseUsageStatPeriod.Year:
      return 0.6;
  }
};

export const getTicketSpace = (period: LicenseUsageStatPeriod) => {
  switch (period) {
    case LicenseUsageStatPeriod.TwentyFourHours:
      return 4;
    case LicenseUsageStatPeriod.ThirtyDays:
      return 5;
    case LicenseUsageStatPeriod.SixtyDays:
      return 10;
    case LicenseUsageStatPeriod.Year:
      return 3;
  }
};

export const getDefaultChartData = (period: LicenseUsageStatPeriod): { x: string; y: { used: number; available: number } }[] => {
  const result: { x: string; y: { used: number; available: number } }[] = [];
  const iteration = getIteration(period);
  const unit = getUnit(period);
  const defaultY = { used: 0, available: 0 };
  const currentDate = getCurrentDate(period);
  for (let i = 0; i < iteration; i++) {
    const date = currentDate.subtract(iteration - i - 1, unit);
    result.push({ x: getLabel(period, date.toString()), y: defaultY });
  }
  return result;
};

export const getLabel = (period: LicenseUsageStatPeriod, value: string) => {
  switch (period) {
    case LicenseUsageStatPeriod.TwentyFourHours:
      return dayjs(value).startOf('h').format('H:mm');
    case LicenseUsageStatPeriod.ThirtyDays:
    case LicenseUsageStatPeriod.SixtyDays:
      return dayjs(value).format('D MMM');
    case LicenseUsageStatPeriod.Year:
      return dayjs(value).format('MMM');
  }
};

const getIteration = (period: LicenseUsageStatPeriod) => {
  switch (period) {
    case LicenseUsageStatPeriod.TwentyFourHours:
      return 24;
    case LicenseUsageStatPeriod.ThirtyDays:
      return 30;
    case LicenseUsageStatPeriod.SixtyDays:
      return 60;
    case LicenseUsageStatPeriod.Year:
      return 12;
  }
};

const getCurrentDate = (switcherValue: LicenseUsageStatPeriod) => {
  switch (switcherValue) {
    case LicenseUsageStatPeriod.TwentyFourHours:
      return dayjs().utc().startOf('h');
    case LicenseUsageStatPeriod.ThirtyDays:
    case LicenseUsageStatPeriod.SixtyDays:
      return dayjs().utc().startOf('d');
    case LicenseUsageStatPeriod.Year:
      return dayjs().utc().startOf('m');
  }
};

const getUnit = (switcherValue: LicenseUsageStatPeriod) => {
  switch (switcherValue) {
    case LicenseUsageStatPeriod.TwentyFourHours:
      return 'h';
    case LicenseUsageStatPeriod.ThirtyDays:
    case LicenseUsageStatPeriod.SixtyDays:
      return 'd';
    case LicenseUsageStatPeriod.Year:
      return 'M';
  }
};
