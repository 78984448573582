import React, { FC, useMemo, useState } from 'react';
import classNames from 'classnames';
import Switcher from '@webteam/switcher';
import { Chart, Legends } from '../../../../../../components/common/chart';
import { getChartRectWidth, getDefaultChartData, getLabel, getTicketSpace } from './helpers';

import { LicenseUsageStatPeriod } from '../../../../../../api/models/licenses';
import api from '../../../../../../api/routes';

import styles from './stats-chart.module.scss';

export const StatsChart: FC<{ licenseId: string; productName?: string }> = ({ licenseId, productName }) => {
  const [switcherValue, setSwitcherValue] = useState(LicenseUsageStatPeriod.TwentyFourHours);
  const chart = api.licenses.useChart(licenseId, switcherValue);

  const chartData = useMemo(() => {
    const defaultData = getDefaultChartData(switcherValue);
    if (chart.data) {
      chart.data.forEach((el) => {
        const label = getLabel(switcherValue, el.timestamp);
        const index = defaultData.findIndex(({ x }) => x === label);
        if (index !== -1) defaultData[index] = { x: label, y: { available: el.total, used: el.used } };
      });
    }
    return defaultData;
  }, [switcherValue, chart.data]);

  const hasChartData = useMemo(() => chart.data && chartData.filter((el) => el.y.used > 0).length !== 0, [chartData, chart.data]);

  return (
    <div className={styles.container}>
      <Switcher
        size="xs"
        value={switcherValue}
        onChange={setSwitcherValue}
        options={[
          { label: '24h', value: LicenseUsageStatPeriod.TwentyFourHours },
          { label: '30 Days', value: LicenseUsageStatPeriod.ThirtyDays },
          { label: '60 Days', value: LicenseUsageStatPeriod.SixtyDays },
          { label: 'All', value: LicenseUsageStatPeriod.Year },
        ]}
      />
      {chart.error ? (
        <p className={classNames('wt-text-1', 'wt-offset-top-24', styles.error)}>{chart.error.title}</p>
      ) : (
        <>
          <div className={classNames(hasChartData && 'wt-offset-top-48')}>
            {!hasChartData && (
              <>
                <p className="wt-text-1 wt-text-1_hardness_average wt-offset-top-24">No usage data yet</p>
                <div className="wt-offset-top-24" />
              </>
            )}
            <Chart
              rectWidth={getChartRectWidth(switcherValue)}
              ticketSpace={getTicketSpace(switcherValue)}
              rectData={chartData.map(({ x, y }) => {
                return { x, y: y.used };
              })}
              maxData={chartData.map(({ x, y }) => {
                return { x, y: y.available };
              })}
            />
          </div>
          <Legends maxTitle="Added licenses" rectTitle={'Allocation of ' + productName ?? 'product'} />
        </>
      )}
    </div>
  );
};
