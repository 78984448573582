import React, { useMemo } from 'react';
import { Col, Row } from '@webteam/layout';
import { ErrorPage } from '../../../../../components/common/error-page';
import { VersionsStatsTable } from './table';
import { StatsChart } from './stats-chart';
import api from '../../../../../api/routes';
import { useParams } from 'react-router-dom';
import { isEmbedded } from '../../../../../common';

const VersionsTab = () => {
  const { licenseId, licensesType } = useParams() as any;
  const licenses = api.licenses.useLicenses(licensesType);

  const license = useMemo(() => licenses.data?.find((el) => el.id === licenseId), [licenses.data, licenseId]);

  if (licenses.error || !licenses.data) return <ErrorPage message={licenses.error?.title} detail={licenses.error?.detail} />;
  let chart = <></>;
  if (!isEmbedded()) {
    chart = (
      <Col className="wt-col-md-12 wt-offset-left-md-0 wt-offset-top-24" offsetLeft={1} span={6}>
        <StatsChart licenseId={licenseId} productName={license?.name} />
      </Col>
    );
  }
  return (
    <Row className="wt-offset-top-24 wt-row-md_direction_column-reverse">
      <Col span={5} className="wt-col-md-12 wt-offset-top-md-48">
        {license && <VersionsStatsTable data={license} />}
      </Col>
      {chart}
    </Row>
  );
};

export default VersionsTab;
