import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { LoadingIcon } from '@webteam/icons';
import { SmallChart } from '../../../common/chart';
import { LicenseUsageStatPeriod } from '../../../../api/models/licenses';
import { getDefaultChartData, getLabel } from './helpers';
import api from '../../../../api/routes';

import styles from '../licenses-table.module.scss';

export const LicensesChart: FC<{ licenseId: string }> = ({ licenseId }) => {
  const period = LicenseUsageStatPeriod.SixtyDays;
  const chart = api.licenses.useChart(licenseId, period);
  const loading = !chart.data && !chart.error;
  const chartData = useMemo(() => {
    const defaultData = getDefaultChartData();
    if (chart.data) {
      chart.data.forEach((el) => {
        const label = getLabel(el.timestamp);
        const index = defaultData.findIndex(({ x }) => x === label);
        if (index !== -1) defaultData[index] = { x: label, y: { available: el.total, used: el.used } };
      });
    }
    return defaultData;
  }, [chart.data]);

  const showChart = useMemo(() => chart.data && chartData.filter((el) => el.y.used > 0).length !== 0, [chartData, chart.data]);

  return (
    <div className={classNames(showChart && styles.chart, styles.chartCell)}>
      {chart.error ? (
        <p className={classNames('wt-text-3', styles.error)}>{chart.error.title}</p>
      ) : loading ? (
        <LoadingIcon />
      ) : showChart ? (
        <SmallChart
          rectData={chartData.map(({ x, y }) => {
            return { x, y: y.used };
          })}
          maxData={chartData.map(({ x, y }) => {
            return { x, y: y.available };
          })}
          rectWidth={0.75}
        />
      ) : (
        <span className="wt-text-3 wt-text-3_hardness_pale">No usage data yet</span>
      )}
    </div>
  );
};
