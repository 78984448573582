import React from 'react';
import { Col, Row } from '@webteam/layout';
import { BADDescriptionRow } from '@bad/components';
import { ThirdMachineSwitcher } from '../../components/settings/third-machine/third-machine-switcher';
import { PlanInfo } from '../../components/settings/plan-info/plan-info';
import { UserAccessInfo } from '../../components/settings/user-access-info/user-access-info';
import api from '../../api/routes';
import { ApiToken } from '../../components/settings/api-token/api-token';
import { NotificationSettings } from '../../components/settings/notification/notifcation-settings';
import { isEmbedded } from '../../common';

export const SettingPage = () => {
  const planInfo = api.server.usePlanInfo();
  const notSingleOptions = planInfo.data && planInfo.data.thirdMachineOptions.available.length > 1;
  let plan;
  if (isEmbedded()) {
    plan = <></>;
  } else {
    plan = <BADDescriptionRow className="wt-offset-top-48" description={<>Plan</>} component={<PlanInfo data={planInfo.data} error={planInfo.error} />} />;
  }
  return (
    <>
      <h2 className="wt-h2 wt-offset-top-48">Settings</h2>
      <Row>
        <Col span="auto-fill">
          {plan}
          <BADDescriptionRow description={<>Users access</>} component={<UserAccessInfo />} />
          {planInfo.data && notSingleOptions && <BADDescriptionRow description={<>Per-user licensing mode</>} component={<ThirdMachineSwitcher data={planInfo.data.thirdMachineOptions} />} />}
          {process.env.REACT_APP_SHOW_NOTIFICATIONS === 'true' && <BADDescriptionRow className="wt-offset-top-48" description={<>Notifications</>} component={<NotificationSettings />} />}
          {!isEmbedded() && <BADDescriptionRow className="wt-offset-top-48" description={<>API access token</>} component={<ApiToken />} />}
        </Col>
      </Row>
    </>
  );
};
