import api from '../../../api/routes';
import { useState } from 'react';
import { BADButton, BADPopup } from '@bad/components';
import styles from './api-token.module.scss';
import { Row } from '@webteam/layout';
import { CloseIcon } from '@webteam/icons';
import { ErrorMessage } from '../../common/error-message/error-message';
import classNames from 'classnames';

interface Props {
  id: string;
  isOpen: boolean;
  onProceed: () => any;
  onAbort: () => any;
}

export const DeleteTokenPopup = ({ id, isOpen, onProceed, onAbort }: Props) => {
  const deleteToken = () => api.apiToken.deleteToken(id);

  const [isLoading, setLoading] = useState(false);
  const [isFailed, setFailed] = useState(false);

  const processSubmit = async () => {
    setLoading(true);
    setFailed(false);
    try {
      await deleteToken();
    } catch (error) {
      console.error(error);
      setFailed(true);
    } finally {
      setLoading(false);
    }
    onProceed();
  };

  const processAbort = () => {
    setLoading(false);
    setFailed(false);
    onAbort();
  };

  return (
    <BADPopup
      primaryActionLabel={<span>Delete token</span>}
      closeActionLabel={<span>Cancel</span>}
      alignButton="end"
      buttonSize="m"
      isReversedButtons={true}
      className={classNames(styles.popup, styles.popupSmall)}
      formikConfig={{
        initialValues: {},
        onSubmit: processSubmit,
      }}
      isOpen={isOpen}
      onRequestClose={processAbort}
      header={
        <Row justify="between" alignItems="center">
          <span className="wt-col-inline">Delete the API access token?</span>
          <BADButton className="wt-col-inline" mode="nude" busy={isLoading} icon={<CloseIcon className={styles.closeButton} />} onClick={processAbort} />
        </Row>
      }
      children={() => (
        <>
          <p className="wt-row">Any scripts or applications that use this token will lose access to the License Vault API.</p>
          {isFailed && <ErrorMessage message={'Unable to delete active token. Try again or contact our support for help.'} />}
        </>
      )}
    />
  );
};
