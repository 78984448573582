import React, { FC, useCallback, useState } from 'react';
import { Col, Row } from '@webteam/layout';
import Switcher from '@webteam/switcher';
import { EventsTable } from './events-table';
import { ProductTable } from './product-table';
import { DenialPeriodType } from '../../../../api/models/server/tickets.denials';
import { ErrorPage } from '../../../../components/common/error-page';
import { ContentSkeleton } from '../../../../components/common/skeleton/content-skeleton';
import { countTotal } from '../../../../common';
import api from '../../../../api/routes';

import { ExportButton } from '../../../../components/dashboard/export/export-button';
import dayjs from 'dayjs';
import { downloadFileByResponse } from '../../../../common/reports';

export const DenialsReportTab = () => {
  const [period, setPeriod] = useState(DenialPeriodType.Day);
  const [search, setSearch] = useState('');

  const fetchEventsTable = useCallback(
    ({ pageIndex, sort, search }) => {
      setSearch(search);
      return api.server.fetchEventsTable(period, pageIndex, sort, search);
    },
    [period],
  );
  const product = api.server.useProduct(period);
  const totalDenialCount = countTotal(product.data?.map((el) => el.denialCount) ?? []);
  const loading = !product.data && !product.error;

  if (loading) return <ContentSkeleton />;

  if (product.error || !product.data) return <ErrorPage message={product.error?.title} detail={product.error?.detail} />;

  return (
    <div className="wt-offset-top-24">
      <Row alignItems="baseline" justify="between">
        <Col span="inline">
          <Row alignItems="baseline">
            <Col span="inline">
              <DenialsHeader numberOfDenials={totalDenialCount} />
            </Col>
            {product.data.length > 0 && (
              <Col span="inline">
                <DenialReportExporter period={period} />
              </Col>
            )}
          </Row>
        </Col>
        <Col span="inline" alignSelf="end">
          <Switcher
            size="xs"
            value={period}
            onChange={(el) => setPeriod(el)}
            options={[
              { label: 'Last 24 hours', value: DenialPeriodType.Day },
              { label: '7 days', value: DenialPeriodType.Week },
            ]}
          />
        </Col>
      </Row>
      <p className="wt-offset-top-24 wt-text-1 wt-text-1_hardness_average">This report keeps track of any license requests that License Vault has denied.</p>
      {product.data.length > 0 ? (
        <>
          <Row className="wt-offset-top-24">
            <Col span={5} className="wt-col-md-10 wt-col-sm-12">
              <ProductTable period={period} data={product.data} productSearch={setSearch} />
            </Col>
          </Row>
          <Row>
            <Col span="auto-fill" className="wt-offset-top-12">
              <EventsTable period={period} fetchTableData={fetchEventsTable} totalDenialCount={totalDenialCount} search={search} />
            </Col>
          </Row>
        </>
      ) : (
        <p className="wt-offset-top-12 wt-text-1 wt-text-1_hardness_average">In the past {parsePeriod(period, true)}, License Vault hasn’t denied any license requests.</p>
      )}
    </div>
  );
};

export const parsePeriod = (value: DenialPeriodType, isShort: boolean = true) => {
  switch (value) {
    case DenialPeriodType.Day:
      return isShort ? '24 hours' : 'Last 24 hours';
    case DenialPeriodType.Week:
      return isShort ? '7 days' : '7 days';
  }
};

const DenialReportExporter: FC<{ period: DenialPeriodType }> = ({ period }) => {
  const convertPeriod = (period: DenialPeriodType) => {
    let timestamp = dayjs();
    switch (period) {
      case DenialPeriodType.Day: {
        timestamp = timestamp.subtract(1, 'day');
        break;
      }
      case DenialPeriodType.Week: {
        timestamp = timestamp.subtract(1, 'week');
        break;
      }
    }
    return timestamp.format('YYYY-MM-DDTHH:MM:ss');
  };
  const fetchReportFile = () => {
    let from = convertPeriod(period);
    api.report.exportDenialsReport(from, null).then((response) => {
      downloadFileByResponse(response);
    });
  };
  return (
    <>
      <ExportButton onClick={fetchReportFile} />
    </>
  );
};

const DenialsHeader: FC<{ numberOfDenials: number }> = ({ numberOfDenials }) => (
  <Row size="xs" alignItems="baseline">
    <Col span="inline">
      <h2 className="wt-h2">{numberOfDenials > 0 ? 'Denials Report' : 'No Denials'}</h2>
    </Col>
  </Row>
);
