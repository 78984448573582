import React, { FC } from 'react';
import classNames from 'classnames';
import { Col, Row } from '@webteam/layout';
import Button from '@webteam/button';
import { PackageIcon } from '@webteam/icons';
import { BADButton } from '@bad/components';
import { PopupPage } from './index';

import styles from './add-or-edit-rule.module.scss';

export const Footer: FC<{
  activePage: PopupPage;
  error?: string;
  setActivePage: (value: PopupPage) => void;
  onRequestCloseAndClearChanges: () => void;
  isSubmitting: boolean;
  onSubmit: () => void;
  onDelete?: () => void;
}> = ({ activePage, error, setActivePage, onRequestCloseAndClearChanges, isSubmitting, onSubmit, onDelete }) => (
  <>
    {error && (
      <Row direction="row-reverse">
        <Col span="inline" className={styles.error}>
          {error}
        </Col>
      </Row>
    )}
    <Row className={classNames(error && 'wt-offset-top-12')} justify="between">
      <Col span="inline">
        <Row alignItems="center">
          {activePage !== PopupPage.Users && (
            <Col span="inline" className="wt-offset-top-md-8">
              <Button
                mode="outline"
                onClick={() => {
                  setActivePage((activePage - 1) as PopupPage);
                }}
              >
                Back
              </Button>
            </Col>
          )}
          {onDelete && (
            <Col span="inline" className="wt-offset-top-md-8">
              <Button size="s" mode="nude" icon={<PackageIcon className={styles.removeIcon} />} onClick={onDelete} />{' '}
            </Col>
          )}
        </Row>
      </Col>
      <Col span="inline">
        <Row size="xs">
          <Col span="inline" className=" wt-col-sm-6 wt-offset-top-md-8">
            <Button mode="outline" onClick={onRequestCloseAndClearChanges}>
              Discard Changes
            </Button>
          </Col>
          <Col span="inline" className="wt-offset-top-md-8">
            <BADButton onClick={onSubmit} busy={isSubmitting}>
              Finish & Save Rule
            </BADButton>
          </Col>
          <Col span="inline" className="wt-offset-top-md-8">
            {activePage !== PopupPage.Test && (
              <Button
                onClick={() => {
                  setActivePage((activePage + 1) as PopupPage);
                }}
              >
                Next
              </Button>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  </>
);
