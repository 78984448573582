import React from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem } from '@webteam/list';
import { getMainMenuSchema } from '../../../shared-interface';
import globalStyles from './portal-page.module.scss';

export const Menu = () => (
  <div className="wt-offset-top-24">
    <List theme="dark">
      {getMainMenuSchema().map(({ title, link, icon }, i) => (
        <NavLink key={JSON.stringify([title, link, i])} to={link} className={({ isActive }) => (isActive ? globalStyles.active : undefined)}>
          <ListItem icon={icon}>{title}</ListItem>
        </NavLink>
      ))}
    </List>
  </div>
);
