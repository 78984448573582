import { LicensesType } from '../api/models/licenses';

type LicensesPathParams = {
  licensesType: LicensesType;
  licenseId: string;
};

export default function makeRoutes(routesPrefix = '') {
  return {
    signup: {
      login: `${routesPrefix}/login`,
      logout: `${routesPrefix}/logout`,
      sign_in: `${routesPrefix}/app/signin`,
    },
    user: {
      currentLicenses: {
        path: `${routesPrefix}/`,
      },
    },
    admin: {
      dashboard: {
        path: `${routesPrefix}/dashboard`,
        tabs: {
          products: `${routesPrefix}/dashboard`,
          users: `${routesPrefix}/dashboard/users`,
          plugins: `${routesPrefix}/dashboard/plugins`,
        },
      },
      licenses: {
        path: `${routesPrefix}/licenses/:licensesType/:licenseId`,
        tabs: {
          versions: ({ licenseId, licensesType }: LicensesPathParams) => `${routesPrefix}/licenses/${licensesType}/${licenseId}`,
          users: ({ licenseId, licensesType }: LicensesPathParams) => `${routesPrefix}/licenses/${licensesType}/${licenseId}/users`,
        },
      },
      users: {
        path: `${routesPrefix}/users/:userId`,
        user: (name: string) => `${routesPrefix}/users/${name}`,
      },
      stats: {
        path:  `${routesPrefix}/stats`,
        tabs: {
          denials: `${routesPrefix}/stats`,
        },
      },
      rules: {
        path: `${routesPrefix}/rules`,
        tabs: {
          list: {
            path: `${routesPrefix}/rules`,
            settings: `${routesPrefix}/rules/settings`,
            testAll: `${routesPrefix}/rules/test`,
            add: `${routesPrefix}/rules/add`,
            edit: (id?: number) => `${routesPrefix}/rules/${id ?? ':id'}/edit`,
            copy: (id?: number) => `${routesPrefix}/rules/${id ?? ':id'}/copy`,
            test: (id?: number) => `${routesPrefix}/rules/${id ?? ':id'}/test`,
            delete: (id?: number) => `${routesPrefix}/rules/${id ?? ':id'}/delete`,
            switch: (id?: number) => `${routesPrefix}/rules/${id ?? ':id'}/switch`,
          },
          priorities: {
            path: `${routesPrefix}/rules/priorities`,
            edit: `${routesPrefix}/rules/priorities/edit`,
            delete: (id?: number) => `${routesPrefix}/rules/priorities/delete/${id ?? ':id'}`,
          },
        },
      },
      settings: {
        path: `${routesPrefix}/settings`,
      },
      currentLicenses: {
        path: `${routesPrefix}/licenses`,
      },
    },
  };
}
