import Toggle from '@webteam/toggle';
import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import styles from './notification.module.scss';

export interface Props {
  isEnabled: boolean;
  enableDescription: boolean;
  isBusy: boolean;
  onChange: (checked: boolean) => any;
}

export const NotificationSourceToggle = ({ isEnabled, enableDescription, isBusy, onChange }: Props) => {
  const renderToggleState = () => <span className="wt-text-2 wt-text-2_hardness_hard">{isEnabled ? 'Enabled' : 'Disabled'}</span>;

  const handleChange = (event: ChangeEvent<{ checked?: boolean }>) => {
    if (!isBusy) {
      onChange(event.target.checked ?? false);
    }
  };

  return (
    <Toggle className={classNames('wt-col-inline', styles.toggle)} checked={isEnabled} onChange={handleChange}>
      {enableDescription && renderToggleState()}
    </Toggle>
  );
};
