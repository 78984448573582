export const markPending = (id: String, ttl: number) => {
  let now = new Date();
  localStorage.setItem(`lv.pending.${id}`, `${now.getTime() + ttl}`);
};

export const isPending = (id: String) => {
  return localStorage.getItem(`lv.pending.${id}`) !== null;
};

export const cleanUpPending = () => {
  let keysToClear = Object.keys(localStorage).map((value) => {
    if (value.startsWith('lv.pending')) {
      let item = localStorage.getItem(value);
      return item !== null && Number.parseInt(item) < new Date().getTime() ? value : '';
    } else {
      return '';
    }
  });
  keysToClear.filter((key) => key.length !== 0).forEach((key) => localStorage.removeItem(key));
};
