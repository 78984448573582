import { AxiosError } from 'axios';
import { routes } from '../../shared-interface';
import { isSignInPage, wasLoggedOut } from '../routes/sessions';
import { Problem } from '../models/problem';
import { LocalStorageKeys } from '../models/localstorage-keys';

const MinimalReloadInterval = 5_000;

const reloadDelay = (): number => {
  const lastReload = localStorage.getItem(LocalStorageKeys.lastReloadTime);
  localStorage.setItem(LocalStorageKeys.lastReloadTime, Date.now().toString());
  if (lastReload && Date.now() - parseInt(lastReload) < MinimalReloadInterval) {
    return MinimalReloadInterval;
  }
  return 0;
};

let timer: NodeJS.Timeout | null = null;
const safeRedirect = (to: string) => {
  if (timer) return;
  const reloadAfter = reloadDelay();

  timer = setTimeout(() => {
    timer = null;
    window.location.href = to;
  }, reloadAfter);
};

const isValidProblem = (object?: any) => {
  return (
    object !== undefined &&
    object !== null &&
    typeof object.type === 'string' &&
    typeof object.title === 'string' &&
    typeof object.status === 'number' &&
    typeof object.detail === 'string' &&
    typeof object.instance === 'string'
  );
};

export const defaultErrorResponseInterceptor = (error: AxiosError): Promise<Problem> => {
  const { response } = error;
  if (!response) {
    return Promise.reject({
      type: 'about:blank',
      title: 'Unknown error',
      status: 0,
      detail: 'Unknown error',
      instance: error.config?.url || '',
    } as Problem);
  }

  const { data, status } = response;
  if (!data || !isValidProblem(data)) {
    return Promise.reject({
      type: 'about:blank',
      title: 'Invalid response from the server',
      status: response.status,
      detail: 'Invalid error response payload from the server',
      instance: error.config?.url || '',
    } as Problem);
  }

  if (status === 401 && !isSignInPage()) {
    if (wasLoggedOut()) {
      safeRedirect(routes().signup.sign_in);
    } else {
      safeRedirect(routes().signup.login);
    }
  }
  return Promise.reject(data);
};

