import React from 'react';
import { GearIcon } from '@webteam/icons';

import { ReactComponent as DashboardIcon } from '../resources/portal-page/menu/dashboard-icon.svg';
import { ReactComponent as StatsIcon } from '../resources/portal-page/menu/stats-icon.svg';
import { ReactComponent as RulesIcon } from '../resources/portal-page/menu/rules-icon.svg';
import { routes } from './app-config';

interface Link {
  title: string;
  link: string;
  icon: JSX.Element;
  target?: '_self' | '_blank';
}

const cachedState: { links: Link[] | null } = { links: null };

export function getMainMenuSchema() {
  return (cachedState.links = cachedState.links || [
    { title: 'Dashboard', link: routes().admin.dashboard.path, icon: <DashboardIcon /> },
    { title: 'Stats', link: routes().admin.stats.path, icon: <StatsIcon /> },
    { title: 'Rules', link: routes().admin.rules.path, icon: <RulesIcon /> },
    { title: 'Settings', link: routes().admin.settings.path, icon: <GearIcon /> },
  ]);
}
