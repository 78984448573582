import styles from './notification.module.scss';
import { Row } from '@webteam/layout';
import Button from '@webteam/button';
import { CloseIcon } from '@webteam/icons';
import { NotificationSource, NotificationSourceCapability, NotificationSourceConfig } from '../../../api/models/settings';
import { BADDescriptionRow, BADPopup } from '@bad/components';
import { RadioButton, RadioButtonList } from '@webteam/radio-button';
import React, { useState } from 'react';
import { NotificationSourceToggle } from './notification-source-toggle';
import { NotificationSourceProductSelect } from './notification-source-product-select';
import api from '../../../api/routes';
import { NotificationThresholdSelect } from './notification-threshold-select';
import { ErrorMessage } from '../../common/error-message/error-message';

interface Props {
  source: NotificationSource;
  onClose: () => any;
  onChange: (source: NotificationSource) => any;
}

enum ProductSelectionMode {
  ALL = 'ALL',
  LIST = 'LIST',
}

export const NotificationSourceSettingsPopup = (props: Props) => {
  const {
    source,
    onClose,
    onChange
  } = props;
  const [ config, setConfig ] = useState<NotificationSourceConfig>(source.config);
  const [ isEnabled, setEnabled ] = useState<boolean>(source.isEnabled);
  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ isFailed, setIsFailed ] = useState<boolean>(false);

  const availableProducts = api.server.rules.useProduct();

  const updateConfig = (config: NotificationSourceConfig) => api.settings.notification.updateConfig(source.id, config);
  const disableSource = () => api.settings.notification.disableSource(source.id);
  const enableSource = () => api.settings.notification.enableSource(source.id);

  const submitProcessor = async () => {
    const preparedConfig: NotificationSourceConfig = (() => {
      if (config.productSet.length === 0) {
        return {
          ...config,
          isForAllProducts: true,
          productSet: [],
        };
      } else {
        return {
          ...config,
          isForAllProducts: false,
        };
      }
    })();

    try {
      setIsFailed(false);
      setIsLoading(true);
      const nextConfig = await updateConfig(preparedConfig);
      const nextSource = await (isEnabled ? enableSource : disableSource)();
      await onChange(nextSource.data);
      onClose();
    } catch (error) {
      console.error(error);
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BADPopup
      primaryActionLabel={<>Save settings</>}
      closeActionLabel={<>Discard changes</>}
      alignButton="end"
      buttonSize="m"
      isOpen={true}
      isReversedButtons={false}
      onRequestClose={onClose}
      className={styles.popup}
      formikConfig={{
        initialValues: {},
        onSubmit: submitProcessor,
      }}
      header={
        <Row justify="between" alignItems="center">
          <span className="wt-col-inline">{source.description}</span>
          <Row size="l">
            <NotificationSourceToggle
              isEnabled={isEnabled}
              enableDescription={true}
              isBusy={isLoading}
              onChange={setEnabled}
            />
            <Button className="wt-col-inline" mode="nude" busy={false} icon={<CloseIcon className={styles.button} />} onClick={onClose} />
          </Row>
        </Row>
      }
    >
      {() => {
        return (
          <>
            {source.capabilitySet.includes(NotificationSourceCapability.PRODUCTS) && (
              <BADDescriptionRow
                className={styles.row}
                description={<span>Notify about</span>}
                component={
                  <>
                    <RadioButtonList
                      disabled={isLoading}
                      size="s"
                      value={config.isForAllProducts ? ProductSelectionMode.ALL : ProductSelectionMode.LIST}
                      onChange={(value) => {
                        if (value === ProductSelectionMode.ALL) {
                          setConfig({
                            ...config,
                            isForAllProducts: true,
                            productSet: [],
                          });
                        } else {
                          setConfig({
                            ...config,
                            isForAllProducts: false,
                            productSet: (availableProducts.data ?? []).map((product) => product.code),
                          });
                        }
                      }}
                    >
                      <RadioButton value={ProductSelectionMode.ALL}>
                        <span className="wt-text-2 wt-text-2_hardness_hard">All products</span>
                      </RadioButton>
                      <RadioButton value={ProductSelectionMode.LIST}>
                        <span className="wt-text-2 wt-text-2_hardness_hard">Selected products</span>
                      </RadioButton>
                    </RadioButtonList>
                    {!config.isForAllProducts && (
                      <NotificationSourceProductSelect
                        selected={config.productSet}
                        isDisabled={isLoading}
                        availableProducts={availableProducts.data ?? []}
                        onChange={(products) => {
                          if (products.length === 0) {
                            setConfig({
                              ...config,
                              isForAllProducts: true,
                              productSet: [],
                            });
                          } else {
                            setConfig({
                              ...config,
                              isForAllProducts: false,
                              productSet: products,
                            });
                          }
                        }}
                      />
                    )}
                  </>
                }
              />
            )}
            {source.capabilitySet.includes(NotificationSourceCapability.THRESHOLD) && (
              <NotificationThresholdSelect
                value={config.threshold}
                isDisabled={isLoading}
                onChange={(value) =>
                  setConfig({
                    ...config,
                    threshold: value,
                  })
                }
              />
            )}
            {isFailed && <ErrorMessage message="Unable to save new settings. Try again or contact our support for help." />}
          </>
        );
      }}
    </BADPopup>
  );
};
