import React, { FC, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import debounce from 'lodash.debounce';
import { List, ListItem } from '@webteam/list';
import { LoadingIcon } from '@webteam/icons';
import Tag from '@webteam/tag';
import Textarea from '@webteam/textarea';
import { Underlayer } from '@bad/components';

import styles from './multiselect.module.scss';

type BADMultiselectSizeType = 's' | 'm';

interface BADMultiselectProps {
  list: { name: string; value: any }[];
  setList: (el: { name: string; value: any }[]) => void;
  searchData?: { name: string; value: any; icon?: JSX.Element }[];
  onSearchAction: (value: string) => void;
  error?: string;
  isLoading?: boolean;
  oneLine?: boolean;
  showDefaultOptions?: boolean;
  placeholder?: string;
  className?: string;
  size?: BADMultiselectSizeType;
}

export const BADMultiselect: FC<BADMultiselectProps> = ({
  list,
  setList,
  searchData,
  onSearchAction,
  error,
  isLoading = false,
  oneLine = false,
  showDefaultOptions = false,
  size = 'm',
  placeholder,
  className,
}) => {
  const [isFocus, toggleFocus] = useState(false);
  const [textareaWidth, setTextareaWidth] = useState(30);
  const [textareaValue, setTextareaValue] = useState<string>('');
  const textareaRef = useRef(null);

  const setDebounceSearch = useMemo(() => debounce(onSearchAction, 400), [onSearchAction]);

  const options = useMemo(() => (searchData ? searchData.filter((el) => !list.find((value) => value.name === el.name)) : []), [searchData, list]);

  const removeValueToList = (value: { name: string; value: any }) => {
    setList([...list.filter((element) => element !== value)]);
  };

  const changeTextareaValue = (value: string) => {
    setTextareaWidth(value.length * 15 + 30);
    setTextareaValue(value);
    setDebounceSearch(value);
  };

  const addNewValueToList = (value: { name: string; value: any }) => {
    setList([...list, value]);
    setTextareaValue('');
    setTextareaWidth(30);
  };

  return (
    <div className={className}>
      {isFocus && (
        <Underlayer
          isShown={true}
          makeVisible={false}
          zIndex={1}
          onClick={() => {
            toggleFocus(false);
          }}
        />
      )}
      <div
        className={classNames(styles.multiselect, oneLine ? styles.oneLine : styles.multiLine, error && styles.multiselectError)}
        onClick={() => {
          // @ts-ignore
          textareaRef.current?.focus();
        }}
      >
        {placeholder && textareaValue.length === 0 && list.length === 0 && <span className={classNames(styles.placeholder, 'wt-text-2', 'wt-text-2_hardness_pale')}>{placeholder}</span>}
        {list.map((el, i) => (
          <Tag key={JSON.stringify([el.name, i])} isRemovable={true} onRemoveClick={() => removeValueToList(el)} size="s">
            {el.name}
          </Tag>
        ))}
        <Textarea
          value={textareaValue}
          ref={textareaRef}
          minRows={1}
          style={{ width: textareaWidth }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
          onFocus={() => {
            toggleFocus(true);
          }}
          onChange={(e) => {
            changeTextareaValue(e.target.value);
          }}
        />
        {isFocus && (searchData || isLoading) && ((showDefaultOptions && list.length === 0) || textareaValue.length > 0) && (
          <div className={classNames(styles.options, size === 's' && styles.optionsSizeS, size === 'm' && styles.optionsSizeM)}>
            <List>
              {isLoading ? (
                <ListItem size={size} icon={<LoadingIcon />} />
              ) : options.length > 0 ? (
                options.map((el, i) => {
                  return (
                    <ListItem
                      size={size}
                      icon={el.icon}
                      key={JSON.stringify([el.name, i])}
                      onClick={() => {
                        addNewValueToList(el);
                      }}
                    >
                      {el.name}
                    </ListItem>
                  );
                })
              ) : (
                <ListItem size={size} disabled={true}>
                  Not found
                </ListItem>
              )}
            </List>
          </div>
        )}
      </div>
      {error && <div className={classNames('wt-text-3', styles.error)}>{error}</div>}
    </div>
  );
};
