import dayjs from 'dayjs';
import React, { FC } from 'react';
import { Col, Row } from '@webteam/layout';
import { BADDatePicker, BADPopup } from '@bad/components';
import { downloadFileByResponse } from '../../../common/reports';
import * as yup from 'yup';

import api from '../../../api/routes';
import { Problem } from '../../../api/models/problem';

type ReportParams = {
  fromDate: string;
  toDate: string;
};

const reportParams: ReportParams = {
  fromDate: dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
  toDate: dayjs().format('YYYY-MM-DD'),
};

const schema = yup.object().shape({
  fromDate: yup.date().required("'From' date is required"),
  toDate: yup.date().required("'To' date is required").min(yup.ref('fromDate'), "'To' date must be after 'From' date"),
});

export const ExportAllData: FC<{
  title: string;
  isOpen: boolean;
  licenseId?: string;
  onRequestClose: () => void;
}> = ({ title, isOpen, licenseId = undefined, onRequestClose }) => {
  return (
    <BADPopup
      header={<>{title}</>}
      primaryActionLabel={<>Export</>}
      onRequestClose={() => {
        onRequestClose();
      }}
      isOpen={isOpen}
      size="s"
      buttonSize="m"
      formikConfig={{
        initialValues: reportParams,
        validationSchema: schema,
        onSubmit: (values: ReportParams, formikHelpers) => {
          api.report
            .exportUsedLicenseReport(values.fromDate, values.toDate, licenseId)
            .then((response) => {
              formikHelpers.setSubmitting(false);
              downloadFileByResponse(response);
              onRequestClose();
            })
            .catch((reason: Problem) => {
              formikHelpers.setSubmitting(false);
              formikHelpers.setStatus({
                error: reason.detail,
              });
            });
        },
      }}
    >
      {() => (
        <>
          <Row>
            <Col span="auto-fill">From</Col>
            <Col span="auto-fill">To</Col>
          </Row>
          <Row>
            <Col span="auto-fill">
              <BADDatePicker name="fromDate" size="s" />
            </Col>
            <Col span="auto-fill">
              <BADDatePicker name="toDate" size="s" />
            </Col>
          </Row>
        </>
      )}
    </BADPopup>
  );
};
