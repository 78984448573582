import React, { FC, useMemo } from 'react';
import classnames from 'classnames';
import { Col, Row } from '@webteam/layout';
import { countGlobal, LicensesTable } from '../../../../components/dashboard/licenses-table';
import { ErrorPage } from '../../../../components/common/error-page';
import { CallHomeButton } from '../../../../components/dashboard/call-home-button';
import api from '../../../../api/routes';
import { LicensesType } from '../../../../api/models/licenses';

import globalStyles from '../../dashboard.module.scss';
import { pluralize } from '../../../../common';

export const PluginsTab = () => {
  const pluginLicenses = api.licenses.useLicenses(LicensesType.Plugin);
  const productLicenses = api.licenses.useLicenses(LicensesType.Product);

  const total = useMemo(() => countGlobal(pluginLicenses.data ?? [], 'available'), [pluginLicenses.data]);
  const used = useMemo(() => countGlobal(pluginLicenses.data ?? [], 'used'), [pluginLicenses.data]);

  if (pluginLicenses.error || !pluginLicenses.data) return <ErrorPage message={pluginLicenses.error?.title} detail={pluginLicenses.error?.detail} />;

  return (
    <div className="wt-offset-top-24">
      <Header total={total} used={used} revalidate={() => Promise.all([pluginLicenses.mutate(), productLicenses.mutate()]).then()} />
      <Row>
        <Col span={12} className={classnames(total === 0 && 'wt-offset-top-24')}>
          {total !== 0 ? (
            <LicensesTable data={pluginLicenses.data ?? []} licensesType={LicensesType.Plugin} />
          ) : (
            <span className="wt-text-1 wt-text-1_hardness_average">Add licenses to your License Vault to start distributing them.</span>
          )}
        </Col>
      </Row>
    </div>
  );
};

const Header: FC<{ total: number; used: number; revalidate: () => Promise<void> }> = ({ total, used, revalidate }) => {
  return (
    <>
      <Row size="xs" alignItems="end">
        <Col span="inline">
          <h2 className="wt-h2">
            {total !== 0 ? total : 'No'} {pluralize(total, 'License')}
          </h2>
        </Col>
        <Col span="inline">
          <CallHomeButton revalidate={revalidate} />
        </Col>
      </Row>
      {total !== 0 && <h3 className={`${globalStyles.usedTitle} wt-h3`}>{used !== 0 ? used : 'No'} Used</h3>}
    </>
  );
};
