import { Col, Row } from '@webteam/layout';
import { BADButton } from '@bad/components';
import api from '../../../api/routes';
import { ErrorMessage } from '../../common/error-message/error-message';
import { Skeleton } from '../../dashboard/users-table/skeleton';
import classNames from 'classnames';
import { CreateTokenPopup } from './create-token-popup';
import { useState } from 'react';
import { RegenerateTokenPopup } from './regenerate-token-popup';
import { DeleteTokenPopup } from './delete-token-popup';
import styles from './api-token.module.scss';

const DOCUMENTATION_LINK = 'https://jb.gg/lv-public-api';
const SWAGGER_UI_LINK = (process.env.REACT_APP_CONTEXT_PATH ?? '') + '/public-api/swagger/';
export const TOKEN_NAME = 'Default';

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const ApiToken = () => {
  const tokens = api.apiToken.useTokens();

  const token = tokens.data?.find((info) => info.name === TOKEN_NAME);

  const isLoading = !tokens.data && !tokens.error;
  const isFailed = tokens.error;
  const isTokenExists = token !== undefined;

  const [isCreatePopupActive, setCreateTokenPopupActive] = useState(false);
  const [isRegeneratePopupActive, setRegeneratePopupActive] = useState(false);
  const [isDeletePopupActive, setDeletePopupActive] = useState(false);

  const renderDate = (date: Date) => {
    if (date === undefined) {
      return <p></p>;
    }
    const now = new Date();
    const timestamp = new Date(date);
    if (now.getDay() === timestamp.getDay()) {
      return (
        <span className="wt-text-3 wt-text-3_hardness_hard">
          Today{' '}
          <span className="wt-text-3_hardness_pale">
            {timestamp.getHours()}:{timestamp.getMinutes()}
          </span>
        </span>
      );
    }
    return (
      <span className="wt-text-3 wt-text-3_hardness_hard">
        {monthNames[timestamp.getMonth()]} {timestamp.getDay()} {', '} {timestamp.getFullYear()}
        <span className="wt-text-3_hardness_pale">
          {String(timestamp.getHours()).padStart(2, '0')}:{String(timestamp.getMinutes()).padStart(2, '0')}
        </span>
      </span>
    );
  };

  return (
    <>
      <p>
        This API lets you automate license management. Consult the{' '}
        <a className="wt-link" target={'_blank'} href={SWAGGER_UI_LINK} rel={'noreferrer'}>
          API specification
        </a>{' '}
        for details.
      </p>
      <p>
        For authorized access to the API, generate an access token.{' '}
        <a className="wt-link" target={'_blank'} href={DOCUMENTATION_LINK} rel={'noreferrer'}>
          Learn more
        </a>{' '}
      </p>
      {isFailed ? (
        <Row className="wt-offset-top-24" alignItems="center" justify="start" size="s">
          <Col span="auto-fill">
            <ErrorMessage message="Unable to load token data from server. Try again or contact our support for help." />
          </Col>
        </Row>
      ) : isLoading ? (
        <Row className="wt-offset-top-24" alignItems="center" justify="start" size="s">
          <Col span="auto-fill">
            <Skeleton />
          </Col>
        </Row>
      ) : (
        <>
          {isTokenExists ? (
            <>
              <Row className="wt-offset-top-24" alignItems="center" justify="start" size="s">
                <p className="wt-col wt-col-inline wt-text-2 wt-text-2_hardness_average">**********************</p>
                <BADButton className="wt-col wt-col-inline" size="s" mode="outline" onClick={() => setRegeneratePopupActive(true)}>
                  <span className="wt-text-2_hardness_primary">Generate new token</span>
                </BADButton>
                <BADButton className="wt-col wt-col-inline" size="s" mode="outline" onClick={() => setDeletePopupActive(true)}>
                  <span className={classNames(styles.red, 'wt-text-2', 'wt-text-2_hardness_hard')}>Delete token</span>
                </BADButton>
              </Row>
              {token?.createdBy && token?.createdAt && (
                <span className="wt-row wt-offset-top-24 wt-text-3 wt-text-3_hardness_hard">
                  Modified by {token.createdBy}
                  <span className={styles.dateDot}>·</span> {renderDate(token.createdAt)}
                </span>
              )}
              {token?.lastAccessAt && (
                <span className="wt-row wt-text-3 wt-text-3_hardness_hard">
                  Last used
                  <span className={styles.dateDot}>·</span> {renderDate(token.lastAccessAt)}
                </span>
              )}
            </>
          ) : (
            <Row className="wt-offset-top-24" alignItems="center" justify="start" size="s">
              <p className="wt-col wt-col-inline wt-text-2_hardness_average">No token generated</p>
              <BADButton className="wt-col wt-col-inline" size="s" mode="outline" onClick={() => setCreateTokenPopupActive(true)}>
                <span className="wt-text-2_hardness_primary">Generate token</span>
              </BADButton>
            </Row>
          )}
        </>
      )}
      <CreateTokenPopup
        isTokenExists={isTokenExists}
        isOpen={isCreatePopupActive}
        onClose={async () => {
          await tokens.mutate();
          setCreateTokenPopupActive(false);
        }}
      />
      {token && (
        <RegenerateTokenPopup
          id={token.id}
          isOpen={isRegeneratePopupActive}
          onProceed={() => {
            setRegeneratePopupActive(false);
            setCreateTokenPopupActive(true);
          }}
          onAbort={() => {
            setRegeneratePopupActive(false);
          }}
        />
      )}
      {token && (
        <DeleteTokenPopup
          id={token.id}
          isOpen={isDeletePopupActive}
          onProceed={async () => {
            await tokens.mutate();
            setDeletePopupActive(false);
          }}
          onAbort={() => {
            setDeletePopupActive(false);
          }}
        />
      )}
    </>
  );
};
