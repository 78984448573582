export enum AccessLevel {
  ADMIN = 'ADMIN',
  USER = 'USER',
  GUEST = 'GUEST',
}

export enum LicenseServerMode {
  Floating = 'FLOATING',
  NonFloating = 'NON_FLOATING',
  //default context value
  NotSet = 'NOT_SET',
}

export interface ServerInfoDto {
  projectName: string;
  serverUid: string;
  lastConnection: Date;
}

export interface ServerPlanDto {
  name: string,
  validUntil?: Date,
  thirdMachineOptions: ThirdMachineOptions
}

export interface UserAccess {
  type: string,
  authorizationServerUrl?: string;
}

export enum ThirdMachineCode {
  Allocate = 'ls.third.machine.allocate',
  Kick = 'ls.third.machine.kick_least_used',
  Prohibited = 'ls.third.machine.error',
}

export interface ThirdMachineOptions {
  available: ThirdMachineCode[];
  current: ThirdMachineCode;
}

export interface CurrentUserDto {
  login: string;
  access: AccessLevel;
}

export interface OnSecondLicenseOption {
  code: string;
}
