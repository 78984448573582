import { useSWRGet } from '../../../swr';
import { DenialStatisticDto } from '../../../models/server/tickets.denials/stats';
import { DenialPeriodType } from '../../../models/server/tickets.denials';
import { ColumnIdWithDirection } from '@bad/components/dist/bad-table/types';
import { TicketDenialEventDto } from '../../../models/server/tickets.denials/events';
import { client } from '../../../../shared-interface';
import { TableData } from '@bad/components';

const commonDenialsUrl = '/tickets/denials';
const eventsUrl = '/events';
const productUrl = '/products';

export const useProduct = (period: DenialPeriodType) => {
  const param = new URLSearchParams();
  param.set('timeSpan', period);
  return useSWRGet<DenialStatisticDto[]>(`${commonDenialsUrl + productUrl}?${param.toString()}`);
};

export const fetchEventsTable = (period: DenialPeriodType, pageIndex: number, sort?: ColumnIdWithDirection<any>, search?: string): Promise<TableData<TicketDenialEventDto>> => {
  const param = new URLSearchParams();
  param.set('pageIndex', pageIndex.toString());
  param.set('timeSpan', period);
  search && param.set('search', search.toString());
  sort && param.set('sort', sort.toString());
  return client().get(`${commonDenialsUrl + eventsUrl}?${param.toString()}`).then(({ data }) => data);
};
