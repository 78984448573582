import React, { FC, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Col, Container, Layout, LayoutContent, LayoutFooter, LayoutHeader, LayoutSidebar, Row } from '@webteam/layout';
import { useSm } from '@webteam/breakpoints';
import { Menu } from './menu';
import { Footer } from './footer';
import { TopBar } from './topbar';
import { ErrorPage } from '../error-page';
import { ReactComponent as LogoComponent } from '../../../resources/portal-page/logo.svg';
import { ReactComponent as MobileLogoComponent } from '../../../resources/portal-page/mobile-logo.svg';
import api from '../../../api/routes';
import { AccessLevel, LicenseServerMode } from '../../../api/models/server';
import { SignInPage } from '../../../pages/signin-page';
import { isSignInPage, wasLoggedOut } from '../../../api/routes/sessions';

import styles from './portal-page.module.scss';

export const PortalPage: FC<{ children: (access: AccessLevel, mode: LicenseServerMode) => JSX.Element }> = ({ children }) => {
  const [isUnauthorizedProblem, setUnauthorizedProblem] = useState(false);
  const showSignInPage = useMemo(() => isUnauthorizedProblem && isSignInPage() && wasLoggedOut(), [isUnauthorizedProblem]);
  const user = api.server.useUser(!showSignInPage);
  const serverMode = api.server.useServerMode(!showSignInPage);
  const serverInfo = api.server.useInfo(!showSignInPage && user.data?.access === AccessLevel.ADMIN);
  const loading = !showSignInPage && ((!user.data && !user.error) || (!serverMode.data && !serverMode.error) || (!serverInfo.data && !serverInfo.error && user.data?.access === AccessLevel.ADMIN));

  const topBarClassName = classNames(styles.topBar, styles.topBarOffset);

  const hideLoadingBanner = () => {
    document.getElementById('loading-banner')?.setAttribute('style', 'display: none;');
  };

  const showLoadingBanner = () => {
    window.location.pathname.startsWith('/app') && document.getElementById('loading-banner')?.setAttribute('style', 'display: block;');
  };

  useEffect(() => {
    if (serverInfo.error?.status === 401 || user.error?.status === 401 || serverMode.error?.status === 401) {
      setUnauthorizedProblem(true);
    } else if (serverInfo.data || serverMode.data || user.data) {
      setUnauthorizedProblem(false);
    }
  }, [serverInfo.data, serverMode.data, serverInfo.error, serverMode.error, user.error, user.data]);

  if (showSignInPage) {
    hideLoadingBanner();
    return (
      <Layout withSidebar>
        <SideBar showMenu={false} />
        <LayoutContent>
          <PortalPageContainer>
            <SignInPage />
          </PortalPageContainer>
        </LayoutContent>
      </Layout>
    );
  }

  if (loading || isUnauthorizedProblem) {
    showLoadingBanner();
    return <></>;
  }

  if ((!serverInfo.data && user.data?.access === AccessLevel.ADMIN) || !serverMode.data || !user.data || serverMode.error || serverInfo.error || user.error) {
    hideLoadingBanner();
    const errorMessage = user.error?.title ?? serverMode.error?.title ?? serverInfo.error?.title ?? 'Invalid data';
    const errorDetails = (serverMode.error?.status ?? serverInfo.error?.status) === 503 ? <>There seems to be a problem with your server configuration.</> : null;
    return (
      <Layout withSidebar>
        <SideBar showMenu={false} />
        <LayoutContent>
          <PortalPageContainer>
            <ErrorPage message={errorMessage} detail={errorDetails} />
          </PortalPageContainer>
        </LayoutContent>
      </Layout>
    );
  }

  hideLoadingBanner();

  return (
    <Layout withSidebar className={styles.layout}>
      <SideBar showMenu={user.data.access === AccessLevel.ADMIN} />
      <LayoutContent>
        <LayoutHeader className={topBarClassName}>
          <TopBar
            isAdmin={user.data.access === AccessLevel.ADMIN}
            projectName={serverInfo.data?.projectName}
            id={serverInfo.data?.serverUid}
            date={serverInfo.data?.lastConnection}
            username={user.data.login}
          />
        </LayoutHeader>
        <PortalPageContainer>{children(user.data.access, serverMode.data)}</PortalPageContainer>
      </LayoutContent>
    </Layout>
  );
};

const SideBar: FC<{ showMenu?: boolean }> = ({ showMenu = true }) => {
  const isSm = useSm();
  const Logo = isSm ? MobileLogoComponent : LogoComponent;
  return (
    <LayoutSidebar className={styles.sidebar}>
      <LayoutHeader>
        <Logo className={styles.logo} />
      </LayoutHeader>
      {showMenu && <Menu />}
      <LayoutFooter className={styles.footer}>
        <Footer showMenu={showMenu} />
      </LayoutFooter>
    </LayoutSidebar>
  );
};

const PortalPageContainer: FC = ({ children }) => (
  <Container className={classNames(styles.container, styles.largeSizeContainer)}>
    <Row className={styles.section}>
      <Col span={12} className="wt-offset-left-xlg-0 wt-col-xlg-12">
        {children}
      </Col>
    </Row>
  </Container>
);
