import { CurrentUserDto, LicenseServerMode, ServerInfoDto, ServerPlanDto, UserAccess } from '../../models/server';
import { useSWRGet } from '../../swr';
import { fetchEventsTable, useProduct } from './tickets.denials.stats';
import rules from './rules';
import { client } from '../../../shared-interface';
import { thirdMachineOptions } from './allocations';

export const defaultRoute = '/server';

const callHomeUrl = '/callhomerequest';
const serverInfoUrl = '/info';
const serverModeUrl = '/mode';
const userUrl = '/users/me';
const serverPlanUrl = '/plan';
const userAccessUrl = '/access';

const useInfo = (shouldFetch: boolean) => useSWRGet<ServerInfoDto>(shouldFetch ? defaultRoute + serverInfoUrl : null);
const useServerMode = (shouldFetch: boolean = true) => useSWRGet<LicenseServerMode>(shouldFetch ? defaultRoute + serverModeUrl : null);
const useUser = (shouldFetch: boolean = true) => useSWRGet<CurrentUserDto>(shouldFetch ? defaultRoute + userUrl : null);

const callHome = () => client().post(defaultRoute + callHomeUrl);
const usePlanInfo = () => useSWRGet<ServerPlanDto>(defaultRoute + serverPlanUrl);
const useUserAccess = () => useSWRGet<UserAccess>(defaultRoute + userAccessUrl);

const serverApi = {
  useInfo,
  useUser,
  useProduct,
  useServerMode,
  fetchEventsTable,
  rules,
  callHome,
  thirdMachineOptions,
  usePlanInfo,
  useUserAccess,
};

export default serverApi;
