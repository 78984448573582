import { useSWRGet } from '../../../swr';
import { NotificationSource, NotificationSourceConfig } from '../../../models/settings';
import { client } from '../../../../shared-interface';

const urls = {
  base: () => '/settings/notification',
  subscribers: () => urls.base() + '/subscribers',
  sources: () => urls.base() + '/sources',
  source: (id: string) => urls.sources() + '/' + id,
  enableSource: (id: string) => urls.source(id) + '/enable',
  disableSource: (id: string) => urls.source(id) + '/disable',
  config: (id: string) => urls.source(id) + '/config',
};

const useSubscribers = () => useSWRGet<string[]>(urls.subscribers());
const useSources = () => useSWRGet<NotificationSource[]>(urls.sources());
const enableSource = (id: string) => client().post<NotificationSource>(urls.enableSource(id));
const disableSource = (id: string) => client().post<NotificationSource>(urls.disableSource(id));
const updateConfig = (id: string, entity: NotificationSourceConfig) => client().patch<NotificationSourceConfig>(urls.config(id), entity);

export const notification = {
  useSources,
  useSubscribers,
  updateConfig,
  enableSource,
  disableSource,
};
