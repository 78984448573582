import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { ConfirmationPopup } from '../../../../../components/common/confirmation-popup';
import { AccessRuleDto } from '../../../../../api/models/server/rules';
import api from '../../../../../api/routes';

export const DeleteRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const { id } = useParams() as { id: string };
  const rules = api.server.rules.useRules();
  const item = rules.data?.find((el) => el.id === +id);
  if (item)
    return (
      <ConfirmationPopup
        successAction={rules.mutate}
        title={`Remove the “${item.name}” Rule?`}
        content={
          <>
            It cannot be undone.
            <br />
            All mentioned users and groups will be able to use all licenses without restrictions.
          </>
        }
        action={() => api.server.rules.deleteRule(item.id)}
        actionButtonName="Remove Rule"
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};

export const SwitchRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const rules = api.server.rules.useRules();
  const { id } = useParams() as { id: string };
  const item: AccessRuleDto | undefined = rules.data?.find((el) => el.id === +id);
  if (item)
    return (
      <ConfirmationPopup
        successAction={rules.mutate}
        title={`${item?.enabled ? 'Disable' : 'Enable'} the “${item.name}” Rule?`}
        action={() =>
          api.server.rules.updateRule(item.id, {
            enabled: !item.enabled,
            name: item.name,
            access: item.access,
            subjectsToAdd: [],
            subjectsToDelete: [],
            productsToAdd: [],
            productsToDelete: [],
          })
        }
        actionButtonName={`${item?.enabled ? 'Disable' : 'Enable'} Rule`}
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};

export const SettingsRulePopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const ruleSettings = api.server.rules.useRuleSettings();
  return (
    <ConfirmationPopup
      successAction={ruleSettings.mutate}
      title={`${ruleSettings.data?.permitUnruledUsers ? 'Forbid' : 'Allow'} users that are not mentioned in the rules to get licenses?`}
      action={() => api.server.rules.setRuleSettings({ permitUnruledUsers: !ruleSettings.data?.permitUnruledUsers })}
      actionButtonName={ruleSettings.data?.permitUnruledUsers ? 'Forbid' : 'Allow'}
      onRequestClose={onRequestClose}
    />
  );
};
