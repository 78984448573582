import { useSWRGet } from '../../../swr';
import { client } from '../../../../shared-interface';
import { PublicApiTokenCreateRequest, PublicApiTokenCreateResponse, PublicApiTokenInfo } from '../../../models/settings/api-token';

const urls = {
  base: () => '/tokens',
  tokens: () => urls.base(),
  token: (id: string) => urls.base() + '/' + id,
};

const useTokens = () => useSWRGet<PublicApiTokenInfo[]>(urls.tokens());
const useToken = (id: string) => useSWRGet<PublicApiTokenInfo>(urls.token(id));
const createToken = (name: string) => client().post<PublicApiTokenCreateResponse>(urls.base(), { name: name } as PublicApiTokenCreateRequest);
const deleteToken = (id: string) => client().delete<PublicApiTokenInfo>(urls.token(id));

export const apiToken = {
  useTokens,
  useToken,
  createToken,
  deleteToken,
};
