import { ChangeEventHandler, ReactElement } from 'react';
import styles from './text-area.module.scss';
import classNames from 'classnames';
import Tooltip from '@webteam/tooltip';
import { Col, Row } from '@webteam/layout';

interface Props {
  className?: string;
  onChange?: (value: string) => void;
  value: string;
  rows?: number;
  icon?: ReactElement;
  disabled?: boolean;
  error?: string;
}

export const TextArea = ({ error = undefined, className = undefined, onChange = undefined, value, rows = 3, icon = undefined, disabled = false }: Props) => {
  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const value = event.target.value;
    onChange?.call(this, value);
    event.preventDefault();
  };

  return (
    <Tooltip placement="bottom-end" isVisible={error !== undefined} content={error}>
      <div className={className}>
        <Row className={classNames(styles.wrapper, error !== undefined ? styles.wrapperError : '')} justify="between" alignItems="center">
          <textarea aria-disabled={disabled} disabled={disabled} rows={rows} className={classNames(styles.textArea, 'wt-col-10', 'wt-code')} value={value} onChange={handleChange} />
          {icon && (
            <Col className={styles.iconWrapper} span="inline">
              {icon}
            </Col>
          )}
        </Row>
      </div>
    </Tooltip>
  );
};
