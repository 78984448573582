import React, { FC, useMemo, useState } from 'react';
import { Cell } from 'react-table';
import classNames from 'classnames';
import { BADPopup, BADTable } from '@bad/components';
import { getTimezone } from '../../common';
import { LicenseTicketDto, ProductTicketDto } from '../../api/models/users';
import LinkButton from '../../components/common/link-button';
import { LicenseLabel, ProductLabel } from '../../components/common/license-table/license-and-product-cell';
import { LastUsageTicketLabel } from '../../components/common/license-table/dates-cell';

import styles from './current-licenses.module.scss';
import api from '../../api/routes';
import { isPending, markPending } from '../../common/pending-changes';
import { Problem } from '../../api/models/problem';

enum CurrentLicensesTableColumnId {
  LastSeen = 'lastSeen',
  Product = 'product',
  License = 'allocatedLicense',
  Hostname = 'userHostName',
  Ip = 'userIP',
  Disconnect = 'disconnect',
}

export const CurrentLicensesTable: FC<{ data: LicenseTicketDto[]; revalidate: () => void }> = ({ data, revalidate }) => {
  return <Table data={data} revalidate={revalidate} />;
};

export interface CurrentLicensesRow extends ProductTicketDto {
  licenseName?: string;
}

const Table: FC<{ data: LicenseTicketDto[]; revalidate: () => void }> = ({ data, revalidate }) => {
  const tableDate = useMemo(
    () =>
      data
        .map((el) => {
          const result: CurrentLicensesRow[] = el.productTickets;
          result[0].licenseName = el.license.name;
          return result;
        })
        .reduce((previousValue, currentValue) => previousValue.concat(currentValue)),
    [data],
  );
  return (
    <BADTable
      className={classNames('wt-text-3', styles.table)}
      offsetTableSize="s"
      wide={true}
      trClassName={(el) => {
        if (el.original.licenseName) {
          return isPending(el.original.id) ? classNames(styles.rowBorder, styles.disconnectPending) : styles.rowBorder;
        }
      }}
      tableData={tableDate}
      columnsFn={() => [
        {
          id: CurrentLicensesTableColumnId.LastSeen,
          Header: () => <span>Last seen (GMT {getTimezone()})</span>,
          minWidth: 16,
          Cell: (value: Cell<CurrentLicensesRow>) => {
            const productTicket = value.row.original;
            if (isPending(productTicket.id)) return <></>;
            return <LastUsageTicketLabel lastUsage={productTicket.dates.lastUsage} release={productTicket.dates.release} />;
          },
        },
        {
          id: CurrentLicensesTableColumnId.Product,
          Header: 'Your product',
          minWidth: 20,
          Cell: (value: Cell<CurrentLicensesRow>) => {
            const productTicket = value.row.original;
            return <ProductLabel name={productTicket.product.name} />;
          },
        },
        {
          id: CurrentLicensesTableColumnId.License,
          Header: 'Allocated license',
          minWidth: 18,
          Cell: (value: Cell<CurrentLicensesRow>) => {
            const productTicket = value.row.original;
            const licenseName = productTicket.licenseName;
            if (!licenseName) return <></>;
            return <LicenseLabel name={licenseName} />;
          },
        },
        {
          id: CurrentLicensesTableColumnId.Hostname,
          Header: 'Hostname',
          accessor: 'hostname',
          minWidth: 20,
        },
        {
          id: CurrentLicensesTableColumnId.Ip,
          Header: 'IP',
          accessor: 'ip',
          minWidth: 10,
        },
        {
          id: CurrentLicensesTableColumnId.Disconnect,
          minWidth: 30,
          Cell: (value: Cell<CurrentLicensesRow>) => {
            const productTicket = value.row.original;
            const licenseTicket = data.find((el) => el.productTickets.find((ticket) => ticket.id === productTicket.id));
            if (!licenseTicket || isPending(productTicket.id)) return <div className={styles.emptyCell} />;
            return <DisconnectComponent revalidate={revalidate} productTicket={productTicket} />;
          },
        },
      ]}
    />
  );
};

const DisconnectComponent: FC<{ productTicket: ProductTicketDto; revalidate: () => void }> = ({ productTicket, revalidate }) => {
  const [isOpenPopup, setOpenPopup] = useState(false);
  return (
    <>
      <LinkButton onClick={() => setOpenPopup(true)} className="wt-text-2 wt-text-2_hardness_primary">
        No longer in use?
      </LinkButton>
      {isOpenPopup && <DisconnectConfirmationPopup successAction={revalidate} onRequestClose={() => setOpenPopup(false)} productTicket={productTicket} />}
    </>
  );
};

const DisconnectConfirmationPopup: FC<{ onRequestClose: () => void; productTicket: ProductTicketDto; successAction: () => void }> = ({ onRequestClose, productTicket, successAction }) => {
  return (
    <BADPopup
      header={<>{`Disconnect ${productTicket.product.name} from License Vault?`}</>}
      primaryActionLabel={<>Disconnect</>}
      onRequestClose={onRequestClose}
      size="s"
      buttonSize="m"
      alignButton="end"
      formikConfig={{
        initialValues: {},
        onSubmit: (values, formikHelpers) => {
          markPending(productTicket.id, 60000);
          api.ticket
            .terminateTicketSession(productTicket.id)
            .then(() => {
              formikHelpers.setSubmitting(false);
              successAction();
            })
            .catch((reason: Problem) => {
              formikHelpers.setSubmitting(false);
              formikHelpers.setStatus({
                error: reason.detail,
              });
            });
        },
      }}
      isOpen={true}
    >
      {() => (
        <span>
          If you’re no longer using <span className={styles.disconnectionDescriptor}>{productTicket.product.name}</span> on{' '}
          <span className={styles.disconnectionDescriptor}>{productTicket.hostname},</span> disconnect it from License Vault to start using your license on a different machine.
        </span>
      )}
    </BADPopup>
  );
};
