import React, {FC} from "react";
import {Col, Row} from "@webteam/layout";
import classNames from "classnames";
import styles from './error-message.module.scss'


export const ErrorMessage: FC<{ message: string | null }> = (props) => {
  return props.message ? <>
    <Row alignItems="center" className={classNames("wt-row_size_xs", styles.errorMsg, "wt-text-2", "wt-text-2_hardness_hard")}>
      <Col span="inline" alignSelf="stretch">{props.message}</Col>
    </Row>
  </> : <></>
}
