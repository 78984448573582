import React, { FC, useState } from 'react';
import { List, ListItem } from '@webteam/list';
import { BADDropdown } from '@bad/components';
import { LicenseOrProductButton } from './license-cell';
import { LicenseTicketDto, ProductTicketDto } from '../../../api/models/users';

import styles from './users-table.module.scss';
import classNames from 'classnames';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { getTimezone } from '../../../common';
import { hasPendingChanges } from './pending-changes';

export const ProductCell: FC<{ licenseTickets: LicenseTicketDto[] }> = ({ licenseTickets }) => {
  if (licenseTickets.length == null) return <></>;
  if (licenseTickets.length === 1 && licenseTickets[0].expectedRelease == null) {
    const licenseTicket = licenseTickets[0];
    const productsStyle = hasPendingChanges(licenseTickets) ? classnames(styles.pendingChangesColor) : undefined;
    return <UsedProducts products={licenseTicket.productTickets} styles={productsStyle} />;
  }
  return <ProductDropdown licenseTickets={licenseTickets} />;
};

const SingleUnused = (lt: LicenseTicketDto) => {
  return (
    <span className={classNames('wt-text-2', 'wt-text-2_hardness_hard')}>
      The license will be automatically released to the pool on {dayjs(lt.expectedRelease).format('MMMM D, YYYY H.mm')} GMT {getTimezone()}
    </span>
  );
};

const MultipleDropdownElementsFactory = (lt: LicenseTicketDto) => {
  return <ProductLabel licenseTicket={lt} />;
};

const ProductDropdown: FC<{ licenseTickets: LicenseTicketDto[] }> = ({ licenseTickets }) => {
  const [isOpen, toggleOpen] = useState(false);
  const itemFactory = licenseTickets.length === 1 && licenseTickets[0].expectedRelease != null ? SingleUnused : MultipleDropdownElementsFactory;
  return (
    <BADDropdown
      size="s"
      placement="bottom-start"
      onRequestClose={() => toggleOpen(false)}
      trigger={<LicenseOrProductButton isOpen={isOpen} toggleOpen={toggleOpen} licenseTickets={licenseTickets} productButton={true} />}
      isOpen={isOpen}
    >
      {
        <div className={styles.dropdownWrapperXs}>
          <List size="s">
            {licenseTickets.map((el, i) => (
              <ListItem key={i} mode={'cell'}>
                {itemFactory(el)}
              </ListItem>
            ))}
          </List>
        </div>
      }
    </BADDropdown>
  );
};
const UsedProducts: FC<{ products: ProductTicketDto[]; styles?: string | undefined }> = ({ products, styles }) => {
  const activeTickets = products
    .map((el) => el.product.name)
    .reduce((acc, el) => {
      let existing = acc.get(el);
      existing ? acc.set(el, ++existing) : acc.set(el, 1);
      return acc;
    }, new Map<string, number>());
  return (
    <>
      <span className={classNames('wt-text-2', 'wt-text-2_hardness_hard', styles)}>
        {Array.from(activeTickets.entries()).map((value, i) => {
          let name = value[0];
          let used = value[1];
          return <span>{`${i > 0 ? ', ' : ''}${used > 1 ? '(' + used + ') ' : ''}${name}`}</span>;
        })}
      </span>
    </>
  );
};

const ReleaseDescription: FC<{ release: Date }> = ({ release }) => {
  return (
    <span className={classNames('wt-text-2', 'wt-text-2_hardness_hard')}>
      Not in use. Will be automatically released to the pool on {dayjs(release).format('MMMM D, YYYY H.mm')} GMT {getTimezone()}
    </span>
  );
};

const ProductLabel: FC<{ licenseTicket: LicenseTicketDto; showForLicenseLabel?: boolean }> = ({ licenseTicket, showForLicenseLabel = false }) => {
  return (
    <>
      <span className={classNames('wt-text-2')}>License for {licenseTicket.license.name}: </span>
      {licenseTicket.expectedRelease ? <ReleaseDescription release={licenseTicket.expectedRelease} /> : <UsedProducts products={licenseTicket.productTickets} />}
    </>
  );
};
