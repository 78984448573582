import React, { FC } from 'react';
import { Breadcrumbs } from '@bad/components';
import { BreadCrumb } from '@bad/components/dist/breadcrumbs';

import styles from './breadcrumbs.module.scss';

export const StyledBreadcrumbs: FC<{
  items?: BreadCrumb[];
  isReactRouterLink?: boolean;
}> = ({ items, isReactRouterLink }) => (
  <div className={styles.resetDefault}>
    <Breadcrumbs items={items} isReactRouterLink={isReactRouterLink} />
  </div>
);
