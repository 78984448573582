import { LoadingIcon } from '@webteam/icons';
import classNames from 'classnames';
import styles from './token-area-icon.module.scss';
import { useRef, useState } from 'react';

interface Props {
  isLoading?: boolean;
  onClick?: () => void;
  message: string;
  lockedMessage: string;
  lockTimeout?: number;
}

export const TokenAreaCopyIcon = ({ isLoading = false, onClick = undefined, message, lockedMessage, lockTimeout = 3000 }: Props) => {
  const timerRef = useRef<NodeJS.Timer | undefined>();
  const [isLocked, setLocked] = useState(false);

  const handleClick = () => {
    if (timerRef.current) {
      return;
    }
    onClick?.call(this);
    setLocked(true);
    timerRef.current = setInterval(() => {
      setLocked(false);
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = undefined;
      }
    }, lockTimeout);
  };

  return (
    <>
      {isLoading ? (
        <LoadingIcon />
      ) : isLocked ? (
        <p className={classNames(styles.controlTextBlocked)}>{lockedMessage}</p>
      ) : (
        <span className={classNames('wt-link', styles.link)} onClick={handleClick}>
          {message}
        </span>
      )}
    </>
  );
};
