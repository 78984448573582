export interface TestRuleResult {
  currentRuleEntry?: RuleEntry;
  result: RuleEntry;
  otherRules: RuleEntry[];
}

export interface TestRuleData {
  currentRule?: BaseAccessRuleDto<BasicRuleSubject | IdentifiableSubject, BasicRuleProduct | IdentifiableProduct>;
  testSubject: BasicRuleSubject;
  testProduct: BasicRuleProduct;
  currentEditedId?: string;
}

export interface RuleEntry {
  id: number;
  name: string;
  outcome: ProductAccess;
  packs: string[];
  participatedSubjects: BasicRuleSubject[];
  overriddenBy?: OverridenRuleType;
}

export interface AccessRuleDto extends BaseAccessRuleDto<IdentifiableSubject, IdentifiableProduct> {
  id: number;
  author: string;
  createdAt: Date;
  lastModified?: Date;
}

export interface BaseAccessRuleDto<S, P> {
  name: string;
  enabled: boolean;
  access: ProductAccess;
  subjects: S[];
  products: P[];
}

export interface UpdateAccessRuleDto {
  name: string;
  enabled: boolean;
  access: ProductAccess;
  subjectsToAdd: BasicRuleSubject[];
  subjectsToDelete: IdentifiableSubject[];
  productsToAdd: BasicRuleProduct[];
  productsToDelete: IdentifiableProduct[];
}

export interface BasicRuleSubject {
  name: string;
  subjectType: RuleSubjectType;
}

export interface IdentifiableSubject extends BasicRuleSubject {
  id: string;
}

export interface BasicRuleProduct {
  name: string;
  code: string;
  type: RuleProductType;
}

export interface IdentifiableProduct extends BasicRuleProduct {
  id: string;
}

export interface RuleSettings {
  permitUnruledUsers: boolean;
}

export enum ProductAccess {
  Permitted = 'PERMITTED',
  Forbidden = 'FORBIDDEN',
}

export enum RuleSubjectType {
  User = 'USER',
  Group = 'GROUP',
}

export enum RuleProductType {
  Product = 'PRODUCT',
  Plugin = ' PLUGIN',
}

export enum OverridenRuleType {
  PersonalRule = 'PERSONAL_RULE',
  Disabled = 'DISABLED',
}
