import React, { FC, useState } from 'react';
import { List, ListItem } from '@webteam/list';
import { InfoIcon } from '@webteam/icons';
import { BADDropdown, BADItemWithIcon } from '@bad/components';
import { LicenseTicketDto } from '../../../api/models/users';
import { LicenseLabel } from '../../common/license-table/license-and-product-cell';

import styles from './users-table.module.scss';
import classnames from 'classnames';
import classNames from 'classnames';
import { hasPendingChanges } from './pending-changes';

export const LicenseCell: FC<{ licenseTickets: LicenseTicketDto[] }> = ({ licenseTickets }) => {
  if (licenseTickets.length === 1) {
    const licenseTicket = licenseTickets[0];
    const labelClass = hasPendingChanges(licenseTickets) ? classNames(styles.pendingChangesColor) : undefined;
    return <LicenseLabel name={licenseTicket.license.name} className={labelClass} />;
  } else if (licenseTickets.length > 1) {
    return <LicenseDropdown licenseTickets={licenseTickets} />;
  } else return <></>;
};

const LicenseDropdown: FC<{ licenseTickets: LicenseTicketDto[] }> = ({ licenseTickets }) => {
  const [isOpen, toggleOpen] = useState(false);
  return (
    <BADDropdown
      size="xs"
      placement="bottom-start"
      onRequestClose={() => toggleOpen(false)}
      trigger={<LicenseOrProductButton isOpen={isOpen} toggleOpen={toggleOpen} licenseTickets={licenseTickets} />}
      isOpen={isOpen}
    >
      <div className={styles.dropdownWrapperXxs}>
        <List size="s">
          {licenseTickets.map((el, i) => (
            <ListItem key={i} className="wt-text-2_hardness_average">
              <LicenseLabel name={el.license.name} />
            </ListItem>
          ))}
        </List>
      </div>
    </BADDropdown>
  );
};

export const LicenseButton: FC<{ isOpen: boolean; toggleOpen: (val: boolean) => void; licenseTickets: LicenseTicketDto[] }> = ({ isOpen, toggleOpen, licenseTickets }) => {
  let hasAnyPendingChanges = hasPendingChanges(licenseTickets);
  if (hasAnyPendingChanges) {
    return (
      <BADItemWithIcon size="xs" spaceBetween="0" className={styles.licenseOrProductDropdownButtonPendingChanges} icon={<InfoIcon className={styles.licenseOrProductDropdownButtonPendingChanges} />}>
        Multiple
      </BADItemWithIcon>
    );
  }
  let numberOfUnusedTickets = licenseTickets.filter((el) => el.expectedRelease != null).length;
  const iconClassNames = numberOfUnusedTickets !== 0 ? classnames(styles.unusedIcon) : undefined;
  return (
    <BADItemWithIcon
      size="xs"
      spaceBetween="0"
      className={styles.licenseOrProductDropdownButton}
      icon={<InfoIcon className={iconClassNames} />}
      onClick={() => {
        toggleOpen(!isOpen);
      }}
    >
      Multiple
    </BADItemWithIcon>
  );
};

export const ProductButton: FC<{ isOpen: boolean; toggleOpen: (val: boolean) => void; licenseTickets: LicenseTicketDto[] }> = ({ isOpen, toggleOpen, licenseTickets }) => {
  let numberOfUnusedTickets = licenseTickets.filter((el) => el.expectedRelease != null).length;
  const buttonText = numberOfUnusedTickets === licenseTickets.length ? 'Not in use' : 'Multiple';
  let hasAnyPendingChanges = hasPendingChanges(licenseTickets);
  if (hasAnyPendingChanges) {
    return (
      <BADItemWithIcon size="xs" spaceBetween="0" className={styles.licenseOrProductDropdownButtonPendingChanges} icon={<InfoIcon className={styles.licenseOrProductDropdownButtonPendingChanges} />}>
        {buttonText}
      </BADItemWithIcon>
    );
  }
  const iconClassNames = numberOfUnusedTickets !== 0 ? classnames(styles.unusedIcon) : undefined;
  const buttonClassNames = numberOfUnusedTickets === licenseTickets.length ? styles.licenseOrProductDropdownButtonUnused : styles.licenseOrProductDropdownButton;
  return (
    <BADItemWithIcon
      size="xs"
      spaceBetween="0"
      className={buttonClassNames}
      icon={<InfoIcon className={iconClassNames} />}
      onClick={() => {
        toggleOpen(!isOpen);
      }}
    >
      {buttonText}
    </BADItemWithIcon>
  );
};

export const LicenseOrProductButton: FC<{ isOpen: boolean; toggleOpen: (val: boolean) => void; licenseTickets: LicenseTicketDto[]; productButton?: Boolean }> = ({
  isOpen,
  toggleOpen,
  licenseTickets,
  productButton,
}) => {
  return productButton ? (
    <ProductButton isOpen={isOpen} toggleOpen={toggleOpen} licenseTickets={licenseTickets} />
  ) : (
    <LicenseButton isOpen={isOpen} toggleOpen={toggleOpen} licenseTickets={licenseTickets} />
  );
};
