import React, { FC } from 'react';
import styles from './link-button.module.scss';
import cn from 'classnames';

// see https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md
const LinkButton: FC<{ onClick?: () => void; className?: string }> = ({ children, onClick, className }) => {
  return (
    <button className={cn(styles.linkButton, className)} onClick={onClick}>
      {children}
    </button>
  );
};

export default LinkButton;
