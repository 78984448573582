import React, { FC, useState } from 'react';
import { BaseAccessRuleDto, BasicRuleProduct, BasicRuleSubject, IdentifiableProduct, IdentifiableSubject, TestRuleData, TestRuleResult } from '../../../../../../../api/models/server/rules';
import { TestRuleContent } from '../../test-rule';
import { FormikRuleData } from '../index';

export const CheckRule: FC<{
  value: FormikRuleData;
  setFieldValue: (field: string, value: any) => void;
  ruleId?: string;
  rule: BaseAccessRuleDto<BasicRuleSubject | IdentifiableSubject, BasicRuleProduct | IdentifiableProduct>;
}> = ({ value, setFieldValue, ruleId, rule }) => {
  const [subjectSelectValue, setSubjectSelectValue] = useState<BasicRuleSubject | undefined>(value.testSubjects);
  const [productSelectValue, setProductSelectValue] = useState<{ label: string; value: string } | undefined>(value.testProducts);
  const [testData, setTestData] = useState<{ response: TestRuleResult; data: TestRuleData } | undefined>(value.testData);
  return (
    <>
      <h3 className="wt-h3 wt-offset-top-24"> Test your current set of rules</h3>
      <TestRuleContent
        ruleId={ruleId}
        rule={rule}
        testData={testData}
        subjectSelectValue={subjectSelectValue}
        productSelectValue={productSelectValue}
        setSubjectSelectValue={(el) => {
          setFieldValue('testSubjects', el);
          setSubjectSelectValue(el);
        }}
        setProductSelectValue={(el) => {
          setFieldValue('testProducts', el);
          setProductSelectValue(el);
        }}
        setTestData={(el) => {
          setFieldValue('testData', el);
          setTestData(el);
        }}
      />
    </>
  );
};
