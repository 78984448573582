import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Col, Row } from '@webteam/layout';
import { RouterTabList, Tab } from '../../components/react-router-tabs/react-router-tabs';
import styles from '../rules/rules.module.scss';

export const StatsPage = () => {
  const pathname = useLocation().pathname;
  return (
    <>
      <Row className="wt-offset-top-48">
        <Col span="auto-fill">
          <RouterTabList>
            <Tab value={pathname}>
              Denials Report
              {/* FIXME: Use an empty <sup> element to compensate for layout shifts when switching tabs, as other tabs contain <sup> tags that make them taller. */}
              <sup className={styles.sup}></sup>
            </Tab>
          </RouterTabList>
        </Col>
      </Row>
      <Outlet />
    </>
  );
};
