import React, { FC } from 'react';
import api from '../../../api/routes';
import { LoadingIcon } from '@webteam/icons';
import classNames from 'classnames';
import { ErrorMessage } from '../../common/error-message/error-message';

export const UserAccessInfo: FC<{}> = () => {
  const userAccess = api.server.useUserAccess();
  if (!userAccess.data && !userAccess.error) return <LoadingIcon />;
  if (userAccess.error || !userAccess.data) return <ErrorMessage message={`Failed to load. Try reloading the page.`} />;
  if (!userAccess.data.authorizationServerUrl) return <></>;
  const documentationUrl = userAccess.data.type === 'tbe' ? 'https://jb.gg/lv-tbe-config' : 'https://jb.gg/ls-hub-config';
  // TODO: Support different texts based on the type of authorization server
  const groupWord = userAccess.data.type === 'tbe' ? 'profiles' : 'groups in JetBrains Hub';

  return (
    <>
      <p className="wt-text-2 wt-text-2_hardness_hard">
        Refer to the{' '}
        <a className={classNames('wt-link')} target={'_blank'} href={documentationUrl} rel={'noreferrer'}>
          documentation
        </a>{' '}
        to configure users and {groupWord}:
      </p>
      <p className="wt-text-2 wt-text-2_hardness_hard">
        <a className={'wt-link'} href={userAccess.data.authorizationServerUrl} target={'_blank'} rel={'noreferrer'}>
          {userAccess.data.authorizationServerUrl}
        </a>
      </p>
    </>
  );
};
