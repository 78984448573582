import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Col, Row } from '@webteam/layout';
import { RouterTabList, Tab } from '../../components/react-router-tabs/react-router-tabs';
import { routes } from '../../shared-interface';
import { ContentSkeleton } from '../../components/common/skeleton/content-skeleton';
import { PrioritiesTabButtons } from './tabs/priorities';
import { ListOfRulesTabButtons } from './tabs/list-of-rules';
import api from '../../api/routes';

import styles from './rules.module.scss';

export const RulesPage = () => {
  const pathname = useLocation().pathname;
  const rules = api.server.rules.useRules();
  const priorities = api.server.rules.priorities.usePriorities();
  const loading = (!rules.data && !rules.error) || (!priorities.data && !priorities.error);
  const onRulesTab = pathname === routes().admin.rules.tabs.list.path;
  const onPrioritiesTab = pathname === routes().admin.rules.tabs.priorities.path;

  if (loading) return <ContentSkeleton />;

  return (
    <>
      <Row className="wt-offset-top-48" size="s">
        <Col span="auto-fill" className="wt-col-md-12">
          <RouterTabList>
            <Tab className={styles.tab} value={routes().admin.rules.tabs.list.path}>
              Rules
              <sup className={styles.sup}> {rules.data ? rules.data.length : 0}</sup>
            </Tab>
            <Tab className={styles.tab} value={routes().admin.rules.tabs.priorities.path}>
              Priorities <sup className={styles.sup} />
            </Tab>
          </RouterTabList>
        </Col>
        {onRulesTab && <ListOfRulesTabButtons />}
        {onPrioritiesTab && <PrioritiesTabButtons />}
      </Row>
      <Outlet />
    </>
  );
};
