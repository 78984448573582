import axios, {AxiosError, InternalAxiosRequestConfig} from 'axios';
import dayjs from 'dayjs';

import {Problem} from '../api/models/problem';

export default function makeHttpClient(
  requestInterceptor?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>,
  errorResponseInterceptor?: (error: AxiosError) => Promise<Problem>
) {
  const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_CONTEXT_PATH  ? `${process.env.REACT_APP_CONTEXT_PATH}/api` : '/api',
    responseType: 'json'
  });
  axiosInstance.interceptors.request.use((config) => {
      if (config.headers) {
          config.headers.Timezone = dayjs.tz.guess();
      }
      return config;
  });
  if (requestInterceptor) {
    axiosInstance.interceptors.request.use(requestInterceptor);
  }
  if (errorResponseInterceptor) {
    axiosInstance.interceptors.response.use(
      (response) => response, errorResponseInterceptor,
    );
  }
  return axiosInstance;
};
