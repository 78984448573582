import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { FlexibleWidthXYPlot, HorizontalGridLines, LineSeries, VerticalRectSeries, XAxis, YAxis, DiscreteColorLegend, Crosshair } from 'react-vis';
import cn from 'classnames';

import styles from './chart.module.scss';
import {pluralize} from "../../../common";

export type ChartDataType = { x: string; y: number }[];

export type TooltipType = undefined | { x: number; y: number }[];

const color = {
  smallRect: 'rgba(22, 125, 255, 0.6)',
  smallMaxRect: 'rgba(241, 134, 126, 1)',
  rect: 'rgba(22, 125, 255, 0.8)',
  maxRect: 'rgba(240, 96, 82, 1)',
  maxLine: '#EF341E',
  gridLine: 'rgba(39, 40, 44, 0.20)',
  text: 'rgba(39, 40, 44, 0.7)',
  tick: 'rgba(39, 40, 44, 0.4)',
};

export const Chart: FC<{ rectData: ChartDataType; maxData?: ChartDataType; ticketSpace: number; rectWidth?: number }> = ({ rectData, maxData, ticketSpace, rectWidth = 0.4 }) => {
  const [tooltipValue, setTooltipValue] = useState<TooltipType>(undefined);
  const [tooltipTitleValue, setTooltipTitleValue] = useState<undefined | string>(undefined);

  const maxLineSeriesData = useMemo(() => {
    const mapData: TooltipType = [];
    if (maxData) {
      maxData.forEach(({ x, y }) => {
        const i = maxData.map((el) => el.x).indexOf(x);
        mapData.push({ x: i - rectWidth / 2, y });
        mapData.push({ x: i + rectWidth / 2, y });
      });
      mapData.length > 0 && mapData.unshift({ x: mapData[0].x - rectWidth / 2, y: mapData[0].y });
      mapData.length > 0 && mapData.push({ x: mapData[mapData.length - 1].x + rectWidth / 2, y: mapData[mapData.length - 1].y });
    }
    return mapData;
  }, [maxData, rectWidth]);

  const rectSeriesData = useMemo(() => getRectSeriesData(rectData, rectWidth), [rectData, rectWidth]);

  const maxRectSeriesData = useMemo(() => getMaxRectSeriesData(rectData, rectSeriesData, maxData), [maxData, rectData, rectSeriesData]);

  const getLeftMargin = (data: ChartDataType) => {
    if (data.filter(({ y }) => y !== 0).length === 0) return 0;
    let max = Math.max(...data.map(({ y }) => y));
    let margin = 20;
    while (max >= 10) {
      margin += 10;
      max = max / 10;
    }
    return margin;
  };

  const getPercent = (a: number, b: number) => {
    if (a === 0 && b === 0) return 0;
    const result = (a / b) * 100;
    return result > 0 && result < 1 ? Math.ceil(result) : Math.floor(result);
  };

  //TODO: Remove after react-vis update version and fix componentWillReceiveProps warning
  console.warn = () => {};

  return (
    <FlexibleWidthXYPlot
      onMouseLeave={() => {
        setTooltipValue(undefined);
        setTooltipTitleValue(undefined);
      }}
      className={cn('wt-text-3', styles.global)}
      height={180}
      margin={{ left: getLeftMargin(maxData ?? rectData), right: 50 }}
    >
      {maxData && <LineSeries strokeStyle="dashed" style={{ stroke: color.maxLine, fill: 'white', strokeDasharray: 2, strokeWidth: 1.5 }} data={maxLineSeriesData} />}
      <VerticalRectSeries
        onNearestX={(val, { index }) => {
          const x = index - 0.03;
          let maxY = 0;
          if (maxData) {
            const indexOfMax = maxData.map((el) => el.x).indexOf(rectData[index].x);
            maxY = maxData[indexOfMax].y;
          }
          setTooltipTitleValue(rectData[index].x);
          setTooltipValue([
            { x, y: rectData[index].y },
            { x, y: maxY },
          ]);
        }}
        stroke="inherit"
        data={rectSeriesData}
        fill={color.rect}
      />
      {maxData && <VerticalRectSeries stroke="inherit" data={maxRectSeriesData} fill={color.maxRect} opacity={1} />}
      <HorizontalGridLines tickTotal={4} style={{ stroke: color.gridLine }} />
      {tooltipValue && (
        <Crosshair className={styles.crosshair} values={tooltipValue}>
          <div className="wt-text-3">
            <div className="wt-text-3_hardness_hard">
              <p>{tooltipValue[0].y}</p>
              <p>{getPercent(tooltipValue[0].y, tooltipValue[1].y)}%</p>
            </div>
            <div>
              <p>{pluralize(tooltipValue[0].y, 'license')}</p>
              <p>usage ratio</p>
            </div>
          </div>
          <div className="wt-text-3 wt-text-3_hardness_pale">{tooltipTitleValue}</div>
        </Crosshair>
      )}
      <XAxis
        hideLine={true}
        marginTop={16}
        tickValues={rectData.map((el, i) => i).filter((el, i) => (i + ticketSpace) % ticketSpace === 0 || i === rectData.length - 1)}
        tickFormat={(i) => rectData[i].x}
        style={{ ticks: { stroke: color.tick }, text: { stroke: 'none', fill: color.text } }}
      />
      <YAxis tickFormat={(val) => (Math.round(val) === val && val > 0 ? val : '')} tickTotal={4} style={{ text: { fill: color.text }, line: { display: ' none' } }} />
    </FlexibleWidthXYPlot>
  );
};

export const Legends: FC<{ maxTitle: string; rectTitle: string }> = ({ maxTitle, rectTitle }) => (
  <div className={cn(styles.legends, 'wt-text-3')}>
    <DiscreteColorLegend className={styles.rectLegends} orientation="vertical" items={[{ title: rectTitle, color: color.rect }]} />
    <DiscreteColorLegend className={styles.maxLineLegends} orientation="vertical" items={[{ title: maxTitle, color: color.maxLine }]} />
  </div>
);

export const SmallChart: FC<{ rectData: ChartDataType; maxData?: ChartDataType; rectWidth?: number }> = ({ rectData, maxData, rectWidth = 0.4 }) => {
  const [tooltipValue, setTooltipValue] = useState<TooltipType>(undefined);
  const [tooltipTitleValue, setTooltipTitleValue] = useState<undefined | string>(undefined);
  const chartRef = useRef(null);

  const rectSeriesData = useMemo(() => getRectSeriesData(rectData, rectWidth), [rectData, rectWidth]);

  const maxRectSeriesData = useMemo(() => getMaxRectSeriesData(rectData, rectSeriesData, maxData), [maxData, rectData, rectSeriesData]);

  useEffect(() => {
    const chartElement = chartRef.current;
    if (chartElement) {
      //@ts-ignore
      const chartSeries = chartElement.container.getElementsByClassName('rv-xy-plot__series--rect');
      const defaultSeries = chartSeries[0].childNodes;
      const maxSeries = chartSeries[1].childNodes;
      defaultSeries.forEach((el: HTMLElement) => (el.style.fill = color.smallRect));
      maxSeries.forEach((el: HTMLElement) => (el.style.fill = color.smallMaxRect));
      if (tooltipValue && tooltipValue[1]) {
        const { x: defaultRectIndex, y: maxRectIndex } = tooltipValue[1];
        if (defaultRectIndex !== -1) defaultSeries.item(defaultRectIndex).style.fill = color.rect;
        if (maxRectIndex !== -1) maxSeries.item(maxRectIndex).style.fill = color.maxRect;
      }
    }
  }, [chartRef, tooltipValue]);

  //TODO: Remove after react-vis update version and fix componentWillReceiveProps warning
  console.warn = () => {};

  return (
    <FlexibleWidthXYPlot
      ref={chartRef}
      onMouseLeave={() => {
        setTooltipValue(undefined);
        setTooltipTitleValue(undefined);
      }}
      className={cn('wt-text-3', styles.global)}
      height={70}
    >
      <VerticalRectSeries
        onNearestX={(val, { index }) => {
          const x = index - 0.03;
          const indexOfMax = getIndexOfMaxSeries(index, maxRectSeriesData ?? [], rectSeriesData);
          setTooltipTitleValue(rectData[index].x);
          setTooltipValue([
            { x, y: rectData[index].y },
            { x: index, y: indexOfMax },
          ]);
        }}
        stroke="inherit"
        data={rectSeriesData}
        fill={color.smallRect}
      />
      {maxData && <VerticalRectSeries stroke="inherit" data={maxRectSeriesData} fill={color.smallMaxRect} opacity={1} />}
      {tooltipValue && (
        <Crosshair className={styles.smallCrosshair} values={tooltipValue}>
          <div className="wt-text-3">
            <div className="wt-text-3_hardness_hard">
              <p>{tooltipValue[0].y}</p>
            </div>
            <div>
              <p>{pluralize(tooltipValue[0].y, 'license')}</p>
            </div>
          </div>
          <div className="wt-text-3 wt-text-3_hardness_pale">{tooltipTitleValue}</div>
        </Crosshair>
      )}
    </FlexibleWidthXYPlot>
  );
};

const getRectSeriesData = (rectData: ChartDataType, rectWidth: number) =>
  rectData.map(({ y }, i) => {
    return { x: i + rectWidth / 2, x0: i - rectWidth / 2, y: y, y0: 0 };
  });

const getMaxRectSeriesData = (rectData: ChartDataType, rectSeriesData: { x: number; x0: number; y: number; y0: number }[], maxData: ChartDataType | undefined) =>
  maxData
    ? rectSeriesData.filter(({ y }, i) => {
        const indexOfMax = getIndexOfMax(i, maxData, rectData);
        return y === maxData[indexOfMax].y;
      })
    : [];

const getIndexOfMax = (index: number, maxData: ChartDataType, rectData: ChartDataType) => maxData.map((el) => el.x).indexOf(rectData[index].x);

const getIndexOfMaxSeries = (index: number, maxData: { x: number; x0: number; y: number; y0: number }[], rectData: { x: number; x0: number; y: number; y0: number }[]) =>
  maxData.map((el) => el.x).indexOf(rectData[index].x);
