import React, { FC, useState } from 'react';
import { Cell } from 'react-table';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { BADTable, BreadCrumb, FetchTableData } from '@bad/components';
import { MenuDropdown } from './menu-dropdown';
import { ProductCell } from './product-cell';
import { LicenseCell } from './license-cell';
import { Skeleton } from './skeleton';
import { LicenseTicketDto, UserDto } from '../../../api/models/users/';
import { routes } from '../../../shared-interface';

import styles from './users-table.module.scss';
import { isPending } from '../../../common/pending-changes';
import { hasPendingChanges } from './pending-changes';

export const UsersTable: FC<{
  fetchUsersTable: FetchTableData<UserDto, string>;
  breadcrumbsItem?: BreadCrumb[];
  className?: string;
}> = ({ fetchUsersTable, breadcrumbsItem, className }) => {
  const [revalidate, toggleRevalidate] = useState(false);
  return (
    <BADTable
      tableDataFnArgs={revalidate}
      offsetTableSize="xs"
      className={classNames(className, styles.menuIconAlign, 'wt-text-3')}
      size="lg"
      tableData={fetchUsersTable}
      showSearch={true}
      searchPlaceholder="Search by username or product"
      columnsFn={() => [
        {
          id: 'userLink',
          Header: 'Username',
          minWidth: 30,
          Cell: (value: Cell<UserDto>) => {
            const username = value.row.original.username;
            const hasUserActiveLicense = value.row.original.licenseTickets.length > 0;
            if (hasUserActiveLicense) {
              return (
                <Link
                  to={{
                    pathname: routes().admin.users.user(value.row.original.id),
                  }}
                  className="wt-text-2 wt-link"
                  state={{
                    breadcrumbsItem: breadcrumbsItem ?? [],
                    username: username,
                  }}
                >
                  {username}
                </Link>
              );
            } else return <span className={classNames('wt-text-2', 'wt-text-2_hardness_pale', styles.usernameWithoutLicensesLabel)}>{username}</span>;
          },
        },
        {
          id: 'allocatedLicensesCount',
          accessor: 'licenseTickets',
          Header: <span className={styles.numberCell}>Allocated licenses</span>,
          minWidth: 10,
          Cell: ({ value }: Cell<{ licenseTickets: LicenseTicketDto[] }>) => {
            const hasUserActiveLicense = value.length > 0;
            if (!hasUserActiveLicense) return <Skeleton />;
            const pendingChanges = hasPendingChanges(value);
            const cellStyle = pendingChanges ? classNames(styles.numberCell, styles.pendingChangesColor) : classNames(styles.numberCell);
            return <span className={cellStyle}>{value.length}</span>;
          },
        },

        {
          id: 'allocatedProducts',
          accessor: 'licenseTickets',
          Header: 'Allocated product / license',
          minWidth: 21,
          Cell: ({ value }: Cell<{ licenseTickets: LicenseTicketDto[] }>) => {
            return <LicenseCell licenseTickets={value} />;
          },
        },

        {
          id: 'userProducts',
          accessor: 'licenseTickets',
          Header: 'User product',
          minWidth: 26,
          Cell: ({ value }: Cell<{ licenseTickets: LicenseTicketDto[] }>) => {
            const hasUserActiveLicense = value.length > 0;
            if (!hasUserActiveLicense) return <Skeleton />;
            return <ProductCell licenseTickets={value} />;
          },
        },

        {
          id: 'menu',
          accessor: 'licenseTickets',
          Header: '',
          minWidth: 5,
          Cell: (value: Cell<UserDto>) => {
            const activeLicenseTicket = value.row.original.licenseTickets.filter((el: LicenseTicketDto) => el.id !== undefined && !isPending(el.id));
            return activeLicenseTicket.length > 0 ? (
              <MenuDropdown username={value.row.original.username} licenseTickets={activeLicenseTicket} revalidate={() => toggleRevalidate(true)} />
            ) : (
              <span className={styles.emptyMenu} />
            );
          },
        },
      ]}
    />
  );
};
