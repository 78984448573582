import { Multiselect, SelectOption } from '@webteam/select';
import { BasicRuleProduct } from '../../../api/models/server/rules';

export interface Props {
  selected: string[];
  isDisabled: boolean;
  availableProducts: BasicRuleProduct[];
  onChange: (selected: string[]) => any;
}

export const NotificationSourceProductSelect = ({ selected, isDisabled, availableProducts, onChange }: Props) => {
  const options = Array.from(
    new Map(
      availableProducts
        .filter((product) => product.code !== 'anyJbProduct' && product.code !== 'anyPlugin')
        .filter((product) => !selected.includes(product.code))
        .map((value) => [value.code, value.name]),
    ),
  ).map((value) => {
    return { label: value[1], value: value[0] };
  });

  const defaultValue = Array.from(
    new Map(
      availableProducts
        .filter((product) => product.code !== 'anyJbProduct' && product.code !== 'anyPlugin')
        .filter((product) => selected.includes(product.code))
        .map((value) => [value.code, value.name]),
    ),
  ).map((value) => {
    return { label: value[1], value: value[0] };
  });

  const internalOnChange = (products: SelectOption[]) => {
    const productIdList = products.map((product: SelectOption) => product.value!!);
    onChange(productIdList);
  };

  return <Multiselect isDisabled={isDisabled} placeholder="Select products" onChange={internalOnChange} options={options} defaultValue={defaultValue} />;
};
