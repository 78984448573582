import styles from './notification.module.scss';
import { Col, Row } from '@webteam/layout';
import { ErrorIcon, LoadingIcon, PenIcon } from '@webteam/icons';
import { NotificationSource, NotificationSourceCapability } from '../../../api/models/settings';
import React, { useState } from 'react';
import { NotificationSourceSettingsPopup } from './notification-source-settings-popup';
import { NotificationSourceToggle } from './notification-source-toggle';
import api from '../../../api/routes';
import classNames from 'classnames';
import { KeyedMutator } from 'swr/dist/types';

interface Props {
  source: NotificationSource;
  sourcesMutate: KeyedMutator<NotificationSource[]>;
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const NotificationSourceEntry = (props: Props) => {
  const { source, sourcesMutate } = props;
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const products = api.server.rules.useProduct();

  const disableSource = () => api.settings.notification.disableSource(source.id);
  const enableSource = () => api.settings.notification.enableSource(source.id);

  const isProductsLoading = !products.data && !products.error;

  const renderProducts = () => {
    if (source.config.isForAllProducts) {
      return 'All';
    }
    if (isProductsLoading) {
      return <LoadingIcon />;
    }
    if (products.error) {
      return <ErrorIcon />;
    }
    const specificProductCodes = source.config.productSet.filter((product) => product !== 'anyJbProduct' && product !== 'anyPlugin');
    const productDescription = specificProductCodes
      .slice(0, 3)
      .map((productCode) => products.data?.find((product) => product.code === productCode)?.name)
      .join(', ');
    if (specificProductCodes.length > 3) {
      return productDescription + ' and ' + (specificProductCodes.length - 3) + ' more';
    }
    return productDescription;
  };

  const renderDate = (date: Date) => {
    if (date === undefined) {
      return <p></p>;
    }
    const now = new Date();
    const timestamp = new Date(date);
    if (now.getDay() === timestamp.getDay()) {
      return (
        <span className="wt-text-3 wt-text-3_hardness_hard">
          Today{' '}
          <span className="wt-text-3_hardness_pale">
            {String(timestamp.getHours()).padStart(2, '0')}:{String(timestamp.getMinutes()).padStart(2, '0')}
          </span>
        </span>
      );
    }
    return (
      <span className="wt-text-3 wt-text-3_hardness_hard">
        {monthNames[timestamp.getMonth()]} {timestamp.getDay()}
        {', '} {timestamp.getFullYear()}{' '}
        <span className="wt-text-3_hardness_pale">
          {String(timestamp.getHours()).padStart(2, '0')}:{String(timestamp.getMinutes()).padStart(2, '0')}
        </span>
      </span>
    );
  };

  const renderThreshold = () => source.config.threshold * 100 + '%';

  const handleToggleChange = async (isEnabled: boolean) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      const nextSource = await (isEnabled ? enableSource : disableSource)();
      await handleChange(nextSource.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = async (nextSource: NotificationSource) => {
    await sourcesMutate((prevSources) => {
      return prevSources?.map((prevSource) => (prevSource.id === nextSource.id ? nextSource : prevSource));
    }, false);
  };

  return (
    <>
      <Row className="wt-offset-top-24" size="m" wrap={false}>
        <NotificationSourceToggle isEnabled={source.isEnabled} enableDescription={false} isBusy={isLoading} onChange={handleToggleChange} />
        <Col span={6}>
          <b>{source.description}</b>
          {source.capabilitySet.includes(NotificationSourceCapability.PRODUCTS) && (
            <p className="wt-text-2 wt-text-2_hardness_average">
              Notify about products: <span className="wt-text-2 wt-text-2_hardness_hard">{renderProducts()}</span>
            </p>
          )}
          {source.capabilitySet.includes(NotificationSourceCapability.THRESHOLD) && (
            <p className="wt-text-2 wt-text-2_hardness_average">
              Usage threshold: <span className="wt-text-2 wt-text-2_hardness_hard">{renderThreshold()}</span>
            </p>
          )}
        </Col>
        <Col span="inline">
          <div onClick={() => setIsModalOpen(true)}>
            <Row className={classNames('wt-link', styles.settingsButton)} size="xs" wrap={false}>
              <PenIcon className="wt-col-inline" color="rgb(22, 125, 255)" />
              <p className="wt-col-inline wt-text-2 wt-text-2_hardness_primary">Settings</p>
            </Row>
          </div>
        </Col>
        <Col span={4}>
          {source.lastUpdateBy !== undefined && <p className="wt-text-3 wt-text-3_hardness_hard">Modified by {source.lastUpdateBy}</p>}
          {source.lastUpdateAt !== undefined && renderDate(source.lastUpdateAt)}
        </Col>
      </Row>
      {isModalOpen && <NotificationSourceSettingsPopup source={source} onClose={() => setIsModalOpen(false)} onChange={handleChange} />}
    </>
  );
};
