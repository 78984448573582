import { BADButton, BADPopup } from '@bad/components';
import styles from './api-token.module.scss';
import api from '../../../api/routes';
import { TOKEN_NAME } from './api-token';
import { useEffect, useState } from 'react';
import { Row } from '@webteam/layout';
import { CloseIcon } from '@webteam/icons';
import classNames from 'classnames';
import { TextArea } from './text-area/text-area';
import { TokenAreaCopyIcon } from './token-area-icon/token-area-copy-icon';
import { TokenAreaGenerateIcon } from './token-area-icon/token-area-generate-icon';

interface Props {
  isOpen: boolean;
  isTokenExists: boolean;
  onClose: () => Promise<any>;
}

export const CreateTokenPopup = ({ isOpen, isTokenExists, onClose }: Props) => {
  const createToken = () => api.apiToken.createToken(TOKEN_NAME);

  const [isFailed, setIsFailed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [secret, setSecret] = useState<string | undefined>(undefined);

  const processPopupClose = async () => {
    if (isLoading) {
      return;
    }
    await onClose();
    setIsFailed(false);
    setSecret(undefined);
  };

  const processTokenGenerate = async () => {
    if (isLoading) {
      return;
    }
    setIsFailed(false);
    setIsLoading(true);
    try {
      let response = await createToken();
      let secret = response.data.secret;
      setSecret(secret);
    } catch (error) {
      console.error(error);
      setIsFailed(true);
    } finally {
      setIsLoading(false);
    }
  };

  const copySecretToClipboard = async () => {
    if (secret) {
      await navigator.clipboard.writeText(secret);
    }
  };

  const renderTokenIcon = () => {
    if (secret !== undefined) {
      return <TokenAreaCopyIcon isLoading={isLoading} message={'Copy'} lockedMessage={'Copied!'} onClick={copySecretToClipboard} />;
    } else {
      return <TokenAreaGenerateIcon isLoading={isLoading} message={'Generate'} onClick={processTokenGenerate} />;
    }
  };

  const getAreaValue = () => {
    if (secret !== undefined) {
      return secret;
    }
    if (isTokenExists) {
      return '**********************';
    } else {
      return '';
    }
  };

  useEffect(() => {
    if (isOpen && !secret) {
      processTokenGenerate().catch((error) => console.error(error));
    }
  });

  return (
    <BADPopup
      className={classNames(styles.popup, styles.popupHeaderUnderlined, styles.popupCreate)}
      isOpen={isOpen}
      onRequestClose={() => !isLoading && processPopupClose()}
      header={
        <Row justify="between" alignItems="center">
          <span className="wt-col-inline">Access token for License Vault API</span>
          <BADButton className="wt-col-inline" mode="nude" busy={isLoading} icon={<CloseIcon className={styles.closeButton} />} onClick={processPopupClose} />
        </Row>
      }
      children={() => (
        <>
          <p className="wt-row wt-offset-top-24">For security reasons, we’ll show you the token only once. Save it in a secure location before closing this dialog.</p>
          <p className="wt-row wt-offset-top-24">If you lose your token, you’ll need to generate a new one. Generating a new token invalidates the current one.</p>
          <TextArea
            className="wt-offset-top-24"
            icon={renderTokenIcon()}
            value={getAreaValue()}
            disabled={true}
            error={isFailed ? 'Unable to create token. Try again or contact our support for help' : undefined}
          />
        </>
      )}
      footer={() => (
        <BADButton busy={isLoading} onClick={processPopupClose}>
          I saved my token
        </BADButton>
      )}
    />
  );
};
