import React from 'react';
import { CurrentLicensesPage } from '../pages/current-licenses';
import { DashboardPage } from '../pages/dashboard';
import { UserHistoryPage } from '../pages/dashboard/user-history';
import { StatsPage } from '../pages/stats';
import { LicensesInfoPage } from '../pages/dashboard/licenses-info';
import { SettingPage } from '../pages/settings';
import { RulesPage } from '../pages/rules';
import { routes } from './app-config';
import { AccessLevel } from '../api/models/server';
import { ProductsTab } from '../pages/dashboard/tabs/products';
import { PluginsTab } from '../pages/dashboard/tabs/plugins';
import { UsersTab } from '../pages/dashboard/tabs/users';
import { DenialsReportTab } from '../pages/stats/tabs/denials-report';
import VersionsTab from '../pages/dashboard/licenses-info/tabs/versions-stats';
import {
  AddRulePopupTab,
  CopyRulePopupTab,
  DeleteRulePopupTab,
  EditRulePopupTab,
  ListOfRulesTab,
  SettingsRulesPopupTab,
  SwitchRulePopupTab,
  TestAllRulesPopupTab,
  TestRulePopupTab,
} from '../pages/rules/tabs/list-of-rules';
import { DeletePrioritiesPopupTab, EditPrioritiesPopupTab, PrioritiesTab } from '../pages/rules/tabs/priorities';

export interface RoutingSchemaItem {
  key: string;
  title: string;
  path: string;
  component: React.FC;
  children?: RoutingSchemaItem[];
}

const cachedRoutingSchema: Record<AccessLevel.ADMIN | AccessLevel.USER, null | RoutingSchemaItem[]> = {
  [AccessLevel.ADMIN]: null,
  [AccessLevel.USER]: null,
};

function getUserRoutingSchema(): RoutingSchemaItem[] {
  return [
    {
      key: 'current-licenses',
      title: 'Licenses',
      path: routes().user.currentLicenses.path,
      component: CurrentLicensesPage,
    },
  ];
}

function getAdminRoutingSchema(): RoutingSchemaItem[] {
  return [
    {
      key: 'dashboard',
      title: 'Dashboard',
      path: routes().admin.dashboard.path,
      component: DashboardPage,
      children: [
        {
          key: 'dashboard-products',
          title: 'Products',
          path: routes().admin.dashboard.tabs.products,
          component: ProductsTab,
        },
        {
          key: 'dashboard-plugins',
          title: 'Plugins',
          path: routes().admin.dashboard.tabs.plugins,
          component: PluginsTab,
        },
        {
          key: 'dashboard-users',
          title: 'Users',
          path: routes().admin.dashboard.tabs.users,
          component: UsersTab,
        },
      ],
    },
    {
      key: 'users',
      title: 'User History',
      path: routes().admin.users.path,
      component: UserHistoryPage,
    },
    {
      key: 'stats',
      title: 'Statistics',
      path: routes().admin.stats.path,
      component: StatsPage,
      children: [
        {
          key: 'stats-denials',
          title: 'Denials report',
          path: routes().admin.stats.tabs.denials,
          component: DenialsReportTab,
        },
      ],
    },
    {
      key: 'license',
      title: 'License Info',
      path: routes().admin.licenses.path,
      component: LicensesInfoPage,
      children: [
        {
          key: 'license-versions',
          title: 'Versions',
          path: routes().admin.licenses.tabs.versions({ licenseId: ':licenseId', licensesType: ':licensesType' as any }),
          component: VersionsTab,
        },
        {
          key: 'license-users',
          title: 'Version users',
          path: routes().admin.licenses.tabs.users({ licenseId: ':licenseId', licensesType: ':licensesType' as any }),
          component: UsersTab,
        },
      ],
    },
    {
      key: 'settings',
      title: 'Settings',
      path: routes().admin.settings.path,
      component: SettingPage,
    },
    {
      key: 'current-licenses',
      title: 'Licenses',
      path: routes().admin.currentLicenses.path,
      component: CurrentLicensesPage,
    },
    {
      key: 'rules',
      title: 'Rules',
      path: routes().admin.rules.path,
      component: RulesPage,
      children: [
        {
          key: 'rules-list',
          title: 'List',
          path: routes().admin.rules.tabs.list.path,
          component: ListOfRulesTab,
          children: [
            {
              key: 'rules-list-settings',
              title: 'Settings',
              path: routes().admin.rules.tabs.list.settings,
              component: SettingsRulesPopupTab,
            },
            {
              key: 'rules-list-test-all',
              title: 'Settings',
              path: routes().admin.rules.tabs.list.testAll,
              component: TestAllRulesPopupTab,
            },
            {
              key: 'rules-list-add',
              title: 'Add Rule',
              path: routes().admin.rules.tabs.list.add,
              component: AddRulePopupTab,
            },
            {
              key: 'rules-list-copy',
              title: 'Copy Rule',
              path: routes().admin.rules.tabs.list.copy(),
              component: CopyRulePopupTab,
            },
            {
              key: 'rules-list-edit',
              title: 'Edit Rule',
              path: routes().admin.rules.tabs.list.edit(),
              component: EditRulePopupTab,
            },
            {
              key: 'rules-list-test',
              title: 'Test Rule',
              path: routes().admin.rules.tabs.list.test(),
              component: TestRulePopupTab,
            },
            {
              key: 'rules-list-delete',
              title: 'Delete Rule',
              path: routes().admin.rules.tabs.list.delete(),
              component: DeleteRulePopupTab,
            },
            {
              key: 'rules-list-switch',
              title: 'Switch Rule',
              path: routes().admin.rules.tabs.list.switch(),
              component: SwitchRulePopupTab,
            },
          ],
        },
        {
          key: 'rules-priorities',
          title: 'Priorities',
          path: routes().admin.rules.tabs.priorities.path,
          component: PrioritiesTab,
          children: [
            {
              key: 'rules-priorities-edit',
              title: 'Edit',
              path: routes().admin.rules.tabs.priorities.edit,
              component: EditPrioritiesPopupTab,
            },
            {
              key: 'rules-priorities-delete',
              title: 'Delete',
              path: routes().admin.rules.tabs.priorities.delete(),
              component: DeletePrioritiesPopupTab,
            },
          ],
        },
      ],
    },
  ];
}

export function getRoutingSchema(access: AccessLevel): RoutingSchemaItem[] | null {
  if (access === AccessLevel.GUEST) {
    return null;
  }
  if (!cachedRoutingSchema[access]) {
    cachedRoutingSchema[access] = access === AccessLevel.USER ? getUserRoutingSchema() : getAdminRoutingSchema();
  }
  return cachedRoutingSchema[access];
}
