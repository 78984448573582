import React, { FC } from 'react';
import { Col, Row } from '@webteam/layout';
import Toggle from '@webteam/toggle';
import { CloseIcon } from '@webteam/icons';
import Button from '@webteam/button';

export const Header: FC<{
  enabled: boolean;
  toggleRule: (value: boolean) => void;
  onRequestCloseAndClearChanges: () => void;
  name?: string;
}> = ({ enabled, onRequestCloseAndClearChanges, toggleRule, name }) => {
  return (
    <Row direction="row-reverse" alignItems="baseline">
      <Col span={6} className="wt-col-sm-12">
        <Toggle
          checked={enabled}
          size="s"
          onChange={(value) => {
            toggleRule(value.target.checked ?? false);
          }}
        >
          Enabled
        </Toggle>
        <Button mode="nude" busy={false} icon={<CloseIcon />} onClick={onRequestCloseAndClearChanges} />
      </Col>
      <Col span={6} className="wt-col-sm-12">
        <h3 className="wt-h3">Edit {name ?? 'New Rule'}</h3>
      </Col>
    </Row>
  );
};
