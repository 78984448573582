import React, { FC, useState } from 'react';
import { Cell } from 'react-table';
import classNames from 'classnames';
import range from 'lodash.range';
import Toggle from '@webteam/toggle';
import { List, ListItem } from '@webteam/list';
import { FilesIcon, MoreIcon, PenIcon, PersonIcon, TrashIcon } from '@webteam/icons';
import { BADButton, BADDropdown, BADTable } from '@bad/components';
import { AccessRuleDto, IdentifiableProduct, IdentifiableSubject } from '../../../../api/models/server/rules';
import { parseDate } from '../../../../common';

import styles from './list-of-rules.module.scss';

export const RuleTable: FC<{
  data: AccessRuleDto[];
  onSwitchRule: (id: number) => void;
  onEditRule: (id: number) => void;
  onDeleteRule: (id: number) => void;
  onTestRule: (id: number) => void;
  onCopyRule: (id: number) => void;
}> = ({ data, onEditRule, onSwitchRule, onDeleteRule, onTestRule, onCopyRule }) => (
  <BADTable
    offsetTableSize="xs"
    size="lg"
    tableData={data}
    className={classNames(styles.menuIconAlign, styles.tableAlign, 'wt-text-3')}
    columnsFn={() => [
      {
        id: 'name',
        Header: 'Name',
        minWidth: 50,
        Cell: (value: Cell<AccessRuleDto>) => (
          <>
            <h4
              onClick={() => {
                onEditRule(value.row.original.id);
              }}
              className={classNames(styles.ruleTitle, !value.row.original.enabled && styles.disableNameRule)}
            >
              {value.row.original.name}
            </h4>
            <div className={classNames('wt-text-2 wt-text-2_hardness_hard', styles.detailBlock)}>
              <span className="wt-text-2_hardness_average">Users: </span>
              <NthAndMore details={value.row.original.subjects.map((el: IdentifiableSubject) => el.name)} />
            </div>
            <div className="wt-text-2 wt-text-2_hardness_hard">
              <span className="wt-text-2_hardness_average">Limits: </span>
              <NthAndMore details={value.row.original.products.map((el: IdentifiableProduct) => el.name)} />
            </div>
          </>
        ),
      },
      {
        id: 'enabled',
        Header: 'On/Off',
        minWidth: 15,
        Cell: (value: Cell<AccessRuleDto>) => (
          <Toggle
            checked={value.row.original.enabled}
            onChange={() => {
              onSwitchRule(value.row.original.id);
            }}
          />
        ),
      },
      {
        id: 'author',
        Header: 'Author',
        accessor: 'author',
        minWidth: 15,
      },
      {
        id: 'lastModified',
        Header: 'Last modified',
        minWidth: 20,
        Cell: (value: Cell<AccessRuleDto>) => parseDate(value.row.original.lastModified ?? value.row.original.createdAt),
      },
      {
        id: 'menu',
        Header: '',
        accessor: 'id',
        minWidth: 5,
        Cell: ({ value }: Cell<{ id: string }>) => (
          <Menu
            onCopyRule={() => {
              onCopyRule(value);
            }}
            onDeleteRule={() => {
              onDeleteRule(value);
            }}
            onEditRule={() => {
              onEditRule(value);
            }}
            onTestRule={() => {
              onTestRule(value);
            }}
          />
        ),
      },
    ]}
  />
);

const Menu: FC<{ onEditRule: () => void; onDeleteRule: () => void; onTestRule: () => void; onCopyRule: () => void }> = ({ onEditRule, onDeleteRule, onTestRule, onCopyRule }) => {
  const [isOpen, setOpen] = useState(false);
  return (
    <BADDropdown
      placement="bottom-end"
      size="xs"
      onRequestClose={() => setOpen(false)}
      trigger={<BADButton onClick={() => setOpen(!isOpen)} mode="nude" icon={<MoreIcon className={styles.paleIcon} />} />}
      isOpen={isOpen}
    >
      <List>
        <ListItem icon={<PenIcon />} onClick={onEditRule}>
          Edit
        </ListItem>
        <ListItem icon={<FilesIcon />} onClick={onCopyRule}>
          Copy
        </ListItem>
        <ListItem icon={<PersonIcon />} onClick={onTestRule}>
          Test Rule
        </ListItem>
        <ListItem icon={<TrashIcon />} onClick={onDeleteRule}>
          Remove
        </ListItem>
      </List>
    </BADDropdown>
  );
};

const NthAndMore: FC<{ details: string[] }> = ({ details }) => {
  const [toggleShow, setToggleShow] = useState(false);
  const itemsToShow = details.length > 3 && details.length - 3 > 1 ? 3 : details.length;
  return (
    <>
      {details.map((el, i) => i < itemsToShow && `${el}${details.length - 1 === i ? '' : ', '}`)}
      {details.length > itemsToShow &&
        (toggleShow ? (
          range(itemsToShow, details.length).map((el: number) => (
            <span className={styles.nthAndMore} key={el} onClick={() => setToggleShow(false)}>
              {details[el]}
              {details.length - 1 === el ? '' : ', '}
            </span>
          ))
        ) : (
          <span className={`wt-text-2 wt-text-2_hardness_primary ${styles.nthAndMore} ${styles.nthAndMoreBlock}`} onClick={() => setToggleShow(true)}>
            {' '}
            {details.length - itemsToShow} more
          </span>
        ))}
    </>
  );
};
