import axios, {AxiosError, AxiosRequestConfig, InternalAxiosRequestConfig} from 'axios';
import makeRoutes from './make-routes';
import makeHttpClient from './make-http-client';
import {Problem} from '../api/models/problem';

const defaultAxiosInstance = axios.create({});

const appConfig = {
  httpClient: defaultAxiosInstance,
  routes: makeRoutes()
};

interface AppInitConfigParams {
  routesPrefix?: string;
  requestInterceptor?: (config: InternalAxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>;
  errorResponseInterceptor?: (error: AxiosError) => Promise<Problem>;
}

export function configureApp({
  routesPrefix,
  requestInterceptor,
  errorResponseInterceptor
}: AppInitConfigParams) {
  appConfig.httpClient = makeHttpClient(requestInterceptor, errorResponseInterceptor);
  appConfig.routes = makeRoutes(routesPrefix || '');
}

export function client() {
  return appConfig.httpClient;
}

export function routes() {
  return appConfig.routes;
}
