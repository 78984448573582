import React, { FC } from 'react';
import { parseDate } from '../../../common';
import dayjs from 'dayjs';
import styles from './license-table.module.scss';

export const FromTicketLabel: FC<{ from: Date }> = ({ from }) => <>{parseDate(from)}</>;

export const LastUsageTicketLabel: FC<{ lastUsage?: Date; release?: Date }> = ({ lastUsage, release }) => {
  const now = dayjs();
  const isOlderThanWeek = dayjs(lastUsage).isBefore(now.subtract(1, 'w'));
  if (release == null)
    if (isOlderThanWeek && lastUsage) {
      return <span className={styles.oldLastUsage}>{parseDate(lastUsage)}</span>;
    } else {
      return <span className={styles.isNow}>Now</span>;
    }

  return <>{parseDate(release)}</>;
};
