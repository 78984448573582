import React, { FC } from 'react';
import { BADInput } from '@bad/components';
import { Col, Row } from '@webteam/layout';
import { UserAndGroupMultiselect } from '../../../../../../../components/rules/users-and-groups-multiselect';
import { FormikErrors } from 'formik';
import { FormikRuleData } from '../index';
import { BasicRuleSubject } from '../../../../../../../api/models/server/rules';

import styles from './popup-pages.module.scss';
import { isEmbedded } from '../../../../../../../common';

export const DefineUsers: FC<{
  list: BasicRuleSubject[];
  setList: (value: BasicRuleSubject[]) => void;
  errors: FormikErrors<FormikRuleData>;
}> = ({ errors, list, setList }) => {
  let placeholder;
  if (isEmbedded()) {
    placeholder = 'User or profile:';
  } else {
    placeholder = 'Username or group from JetBrains Hub:';
  }
  console.log(placeholder);
  return (
    <>
      <h3 className="wt-h3 wt-offset-top-24">
        Name the rule and specify <span className={styles.highlighted}>who</span> requests a license
      </h3>
      <Row className="wt-offset-top-24">
        <Col span="auto-fill">
          <BADInput label={<span className="wt-text-2 wt-text-2_hardness_hard">Rule name:</span>} name="name" />
        </Col>
      </Row>
      <Row className="wt-offset-top-24">
        <Col span="auto-fill">
          <span>{placeholder}</span>
          <UserAndGroupMultiselect multiselectError={errors.subjects as string} list={list} setList={setList} />
        </Col>
      </Row>
    </>
  );
};
