import React, { FC, useState } from 'react';
import { PersonIcon, TeamIcon } from '@webteam/icons';
import { BADMultiselect } from '../multiselect';
import { BasicRuleSubject, RuleSubjectType } from '../../../api/models/server/rules';
import api from '../../../api/routes';

export const UserAndGroupMultiselect: FC<{
  list: BasicRuleSubject[];
  setList: (el: BasicRuleSubject[]) => void;
  multiselectError?: string;
  className?: string;
}> = ({ list, setList, multiselectError, className }) => {
  const [searchValue, setSearchValue] = useState('');
  const subject = api.server.rules.useSubject(searchValue);

  const loading = !subject.data && !subject.error;

  return (
    <>
      <BADMultiselect
        error={multiselectError}
        size="s"
        className={className}
        isLoading={loading}
        list={list.map((el) => {
          return { name: el.name, value: el };
        })}
        setList={(el) => {
          setList(el.map((el) => el.value));
        }}
        searchData={subject.data?.map((el) => {
          const icon = el.subjectType === RuleSubjectType.Group ? <TeamIcon /> : <PersonIcon />;
          return {
            name: el.name,
            value: el,
            icon,
          };
        })}
        onSearchAction={(value) => {
          setSearchValue(value);
        }}
      />
    </>
  );
};
