import React, { FC } from 'react';
import { Popup, PopupContent, PopupHeader } from '@webteam/popup';
import classNames from 'classnames';
import { ReactComponent as PopupImage } from '../../../resources/settings/third-machine-option/third-machine-popup.svg';

export const ThirdMachinePopup: FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Popup isOpen={open} onRequestClose={() => onClose()}>
      <PopupHeader>
        <span className={classNames('wt-h3')}>3rd Machine Mode</span>
      </PopupHeader>
      <PopupContent>
        <PopupImage />
      </PopupContent>
    </Popup>
  );
};
