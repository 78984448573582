import { BADTooltip } from '@bad/components';
import { NotificationSourceEntry } from './notification-source-entry';
import api from '../../../api/routes';
import { LoadingIcon } from '@webteam/icons';
import { ErrorMessage } from '../../common/error-message/error-message';
import { NotificationSource } from '../../../api/models/settings';

export const NotificationSettings = () => {
  const subscribers = api.settings.notification.useSubscribers();
  const sources = api.settings.notification.useSources();

  const isSubscribersLoading = !subscribers.data && !subscribers.error;
  const isSourcesLoading = !sources.data && !sources.error;

  const subscribersTooltip = (subscriberNameList: string[]) => {
    if (subscribers.error) {
      return <ErrorMessage message="Unable to load notification subscribers. Try again or contact our support for help." />;
    }
    return subscriberNameList?.map((subscriber: string, key: number) => <p key={key}>{subscriber}</p>);
  };

  return (
    <>
      <p>
        If enabled, notification emails are sent once a day to{' '}
        {(() => {
          if (isSubscribersLoading || !subscribers.data || subscribers.data.length === 0) {
            return 'all administrators';
          }
          return <BADTooltip placement={'top-start'} children={<span className={'wt-link'}>all administrators</span>} content={subscribersTooltip(subscribers.data)} />;
        })()}
      </p>
      {(() => {
        if (isSourcesLoading) {
          return <LoadingIcon />;
        }
        if (sources.error) {
          return <ErrorMessage message="Unable to load notification settings. Try again or contact our support for help." />;
        }
        return sources.data
          ?.sort((left: NotificationSource, right: NotificationSource) => left.order - right.order)
          .map((source: NotificationSource, key: number) => <NotificationSourceEntry key={key} source={source} sourcesMutate={sources.mutate} />);
      })()}
    </>
  );
};
