import dayjs from 'dayjs';
import React from 'react';

//FIXME: This should not be used. Server sends valid versions. Revise flow with versions display
export const parseVersion = (value: number) => `${Math.floor(value / 1000)}.${(value % 1000) / 100}`;

export const getTimezone = () => dayjs().format('Z');

export const parsePercent = (a: number, b: number) => (a === 0 ? 100 : b === 0 ? 0 : 100 - Math.round((a / b) * 100));

export const isEmbedded = () => process.env.REACT_APP_IS_EMBEDDED === 'true';

export const countTotal = (a: number[]) => {
  let total = 0;
  a.forEach((el) => (total += el));
  return total;
};

export const parseDate = (value: Date) => (
  <>
    {dayjs(value).calendar(undefined, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'MMM D, YYYY ',
      sameElse: 'MMM D, YYYY',
    })}{' '}
    <span className="wt-text-2_hardness_pale">{dayjs(value).format('HH:mm')}</span>
  </>
);

/**
 * Replace with lingui Plural later
 */
export const pluralize = (amount: number, measure: string): string => {
  if (measure === 'was' || measure === 'were') {
    return amount === 1 ? 'was' : 'were';
  } else {
    return amount === 1 ? measure : `${measure}s`;
  }
};
