import { BADDescriptionRow } from '@bad/components';
import styles from './notification.module.scss';
import { Select } from '@webteam/select';
import React from 'react';

export interface Props {
  isDisabled: boolean;
  value: number;
  onChange: (value: number) => any;
}

export const NotificationThresholdSelect = ({ isDisabled, value, onChange }: Props) => {
  const options = [
    { label: '70%', value: '0.7', realValue: 0.7 },
    { label: '80%', value: '0.8', realValue: 0.8 },
    { label: '90%', value: '0.9', realValue: 0.9 },
  ];

  const transformToOption = (thresholdValue: number) => {
    return options.find((value) => value.realValue === thresholdValue) ?? options[0];
  };

  return (
    <BADDescriptionRow
      description={<span>Usage threshold</span>}
      component={
        <Select
          className={styles.thresholdSelect}
          isDisabled={isDisabled}
          defaultValue={transformToOption(value)}
          isSearchable={true}
          onChange={({ value }) => onChange(parseFloat(value))}
          options={options}
        />
      }
    />
  );
};
