export interface NotificationProduct {
  code: string;
  name: string;
}

export interface NotificationSourceConfig {
  threshold: number;
  isForAllProducts: boolean;
  productSet: string[];
}

export interface NotificationSource {
  id: string;
  order: number;
  description: string;
  capabilitySet: NotificationSourceCapability[];
  config: NotificationSourceConfig;
  isEnabled: boolean;
  lastUpdateAt?: Date;
  lastUpdateBy?: string;
}

export enum NotificationSourceCapability {
  PRODUCTS = 'PRODUCTS',
  THRESHOLD = 'THRESHOLD',
}
