import React, { FC, useState } from 'react';
import classnames from 'classnames';
import { AxiosResponse } from 'axios';
import { Col, Row } from '@webteam/layout';
import { Popup, PopupContent } from '@webteam/popup';
import Button from '@webteam/button';

import styles from './confirmation-popup.module.scss';

export const ConfirmationPopup: FC<{ title: string; content?: JSX.Element; action: () => Promise<AxiosResponse>; successAction: () => void; actionButtonName: string; onRequestClose: () => void }> = ({
  title,
  content,
  action,
  actionButtonName,
  successAction,
  onRequestClose,
}) => {
  const [error, setError] = useState();
  return (
    <Popup onRequestClose={onRequestClose}>
      <PopupContent>
        <h3 className="wt-h3">{title}</h3>
        <div className="wt-text-2 wt-text-2_hardness_hard wt-text-2_flow">{content ?? ''}</div>
        {error && <div className={classnames(styles.error, 'wt-text-2', 'wt-offset-top-12')}>{error}</div>}
        <Row className="wt-offset-top-24" size="s">
          <Col span="inline">
            <Button
              onClick={() => {
                action()
                  .then(() => {
                    onRequestClose();
                    successAction();
                  })
                  .catch((error) => {
                    setError(error.detail);
                  });
              }}
            >
              {actionButtonName}
            </Button>
          </Col>
          <Col span="inline">
            <Button mode="outline" onClick={onRequestClose}>
              Cancel
            </Button>
          </Col>
        </Row>
      </PopupContent>
    </Popup>
  );
};
