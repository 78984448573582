import React from 'react';
import classNames from 'classnames';
import { Col, Row } from '@webteam/layout';
import { CurrentLicensesTable } from './table';
import { ErrorPage } from '../../components/common/error-page';
import { ContentSkeleton } from '../../components/common/skeleton/content-skeleton';
import api from '../../api/routes';

import styles from './current-licenses.module.scss';

export const CurrentLicensesPage = () => {
  const licenses = api.users.useLicenses();
  const user = api.server.useUser();

  const loading = !licenses.error && !licenses.data;
  if (loading) return <ContentSkeleton />;
  if (licenses.error || !licenses.data) return <ErrorPage message={licenses.error?.title} detail={licenses.error?.detail} />;

  const hasAllocatedLicenses = licenses.data.length > 0;
  return (
    <>
      <h2 className="wt-h2 wt-offset-top-48">{hasAllocatedLicenses ? 'Your licenses' : 'No licenses'}</h2>
      <h3 className="wt-h3">
        {hasAllocatedLicenses ? (
          <>
            <span className={styles.subtitle}>The licenses below are allocated to your account</span> <span className={styles.boldText}>{user.data?.login}</span>
          </>
        ) : (
          <>
            <span className={styles.subtitle}>Licenses allocated to your account</span> <span className={styles.boldText}>{user.data?.login}</span>{' '}
            <span className={styles.subtitle}>will appear on this page</span>
          </>
        )}
      </h3>
      {hasAllocatedLicenses ? (
        <CurrentLicensesTable
          data={licenses.data}
          revalidate={() => {
            licenses.mutate(undefined, { revalidate: true });
          }}
        />
      ) : (
        <>
          <Row className="wt-offset-top-24">
            <Col span="inline" className="wt-col-lg-8 wt-col-md-10 wt-col-sm-11">
              <div className={styles.domainBox}>
                <Row alignItems="center">
                  <Col span="auto-fill" className="wt-col-md-12">
                    <h3 className={classNames('wt-h3', styles.domainTitle)}>{window.location.origin}</h3>
                    <p className="wt-text-2 wt-text-2_hardness_hard">Enter this URL in your JetBrains product to activate it</p>
                  </Col>
                  <Col span="inline">
                    <button className={classNames('wt-link wt-text-2', styles.copy)} onClick={() => navigator.clipboard.writeText(window.location.origin)}>
                      Copy
                    </button>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <p className="wt-offset-top-24 wt-text-1 wt-text-1_hardness_hard">
            Refer to our documentation for step-by-step{' '}
            <a rel="noopener noreferrer" href="https://jb.gg/ls-activate-ide" target="_blank" className="wt-link">
              activation instructions
            </a>
            {'. '}
            If you run into any issues while obtaining a license, contact your License Vault administrator.
          </p>
        </>
      )}
    </>
  );
};
