import dayjs from 'dayjs';

export const getDefaultChartData = (): { x: string; y: { used: number; available: number } }[] => {
  const result: { x: string; y: { used: number; available: number } }[] = [];
  const defaultY = { used: 0, available: 0 };
  const currentDate = dayjs().utc().startOf('d');
  for (let i = 0; i < 60; i++) {
    const date = currentDate.subtract(60 - i - 1, 'd');
    result.push({ x: getLabel(date.toString()), y: defaultY });
  }
  return result;
};

export const getLabel = (value: string) => dayjs(value).format('D MMM');
