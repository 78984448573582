import React, { useMemo } from 'react';
import { Outlet } from 'react-router-dom';
import { RouterTabList, Tab } from '../../components/react-router-tabs/react-router-tabs';
import { Col, Row } from '@webteam/layout';
import { ContentSkeleton } from '../../components/common/skeleton/content-skeleton';
import { routes } from '../../shared-interface';
import { LicensesType } from '../../api/models/licenses';
import { countGlobal } from '../../components/dashboard/licenses-table';
import api from '../../api/routes';

import styles from './dashboard.module.scss';

export const DashboardPage = () => {
  const productLicenses = api.licenses.useLicenses(LicensesType.Product);
  const pluginLicenses = api.licenses.useLicenses(LicensesType.Plugin);
  const usersTotal = api.users.useUsersTotal();

  const productTotal = useMemo(() => countGlobal(productLicenses.data ?? [], 'available'), [productLicenses.data]);
  const pluginTotal = useMemo(() => countGlobal(pluginLicenses.data ?? [], 'available'), [pluginLicenses.data]);

  const loading = (!productLicenses.data && !productLicenses.error) || (!pluginLicenses.data && !pluginLicenses.error) || (!usersTotal.data && !usersTotal.error);

  if (loading) return <ContentSkeleton />;

  return (
    <Row className="wt-offset-top-48">
      <Col span="auto-fill">
        <RouterTabList>
          <Tab className={styles.tab} value={routes().admin.dashboard.tabs.products}>
            JetBrains Products <sup className={styles.sup}>{productTotal}</sup>
          </Tab>
          <Tab className={styles.tab} value={routes().admin.dashboard.tabs.plugins}>
            3rd-Party Plugins <sup className={styles.sup}>{pluginTotal}</sup>
          </Tab>
          <Tab className={styles.tab} value={routes().admin.dashboard.tabs.users}>
            Active Users <sup className={styles.sup}>{usersTotal.data?.total}</sup>
          </Tab>
        </RouterTabList>
        <Outlet />
      </Col>
    </Row>
  );
};
