import classNames from 'classnames';
import styles from './token-area-icon.module.scss';
import { LoadingIcon } from '@webteam/icons';

interface Props {
  isLoading?: boolean;
  message: string;
  onClick: () => void;
}

export const TokenAreaGenerateIcon = ({ isLoading = false, message, onClick }: Props) => {
  return isLoading ? (
    <LoadingIcon />
  ) : (
    <span className={classNames('wt-link', styles.link)} onClick={onClick}>
      {message}
    </span>
  );
};
