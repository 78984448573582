import React, { FC, useMemo, useState } from 'react';
import { BADMultiselect } from '../multiselect';
import { BasicRuleProduct } from '../../../api/models/server/rules';
import api from '../../../api/routes';

export const ProductMultiselect: FC<{
  list: BasicRuleProduct[];
  setList: (el: BasicRuleProduct[]) => void;
  multiselectError?: string;
  className?: string;
}> = ({ list, setList, multiselectError, className }) => {
  const product = api.server.rules.useProduct();
  const [searchValue, setSearchValue] = useState('');
  const search = useMemo(() => {
    if (!product.data) return [];
    if (setSearchValue.length > 0)
      return product.data
        .filter((el) => el.name.toLowerCase().includes(searchValue.toLowerCase()))
        .map((el) => {
          return { name: el.name, value: el };
        });
    return product.data.map((el) => {
      return { name: el.name, value: el };
    });
  }, [product.data, searchValue]);
  return (
    <>
      <BADMultiselect
        error={multiselectError}
        oneLine={true}
        showDefaultOptions={true}
        className={className}
        isLoading={false}
        list={list.map((el) => {
          return { name: el.name, value: el };
        })}
        setList={(el) => {
          setList(el.map((el) => el.value));
        }}
        searchData={search}
        onSearchAction={(value) => {
          setSearchValue(value);
        }}
        placeholder="Any licenses"
      />
    </>
  );
};
