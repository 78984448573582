import { client } from '../../../shared-interface';
import { ColumnIdWithDirection } from '@bad/components/dist/bad-table/types';
import { useSWRGet } from '../../swr';
import { HistoryTicketDto, LicenseTicketDto, UserDto } from '../../models/users';
import { TableData } from '@bad/components';

export const usersUrl = '/users';
export const historyUrl = '/history';
const licensesUrl = '/me/allocated';

const useUsersHistory = (userId: string) => useSWRGet<HistoryTicketDto>(usersUrl + '/' + userId + historyUrl);
const useLicenses = () => useSWRGet<LicenseTicketDto[]>(usersUrl + licensesUrl);

const fetchUsersTable = (pageIndex: number, sort?: ColumnIdWithDirection<any>, search?: string, licenseId?: string): Promise<TableData<UserDto>> => {
  const param = new URLSearchParams();
  param.set('pageIndex', pageIndex.toString());
  search && param.set('search', search.toString());
  sort && param.set('sort', sort.toString());
  licenseId && param.set('licenseId', licenseId);
  return client().get(`${usersUrl}?${param.toString()}`).then(({ data }) => data);
};

const useUsersTotal = (licenseId?: string) => {
  const param = new URLSearchParams();
  param.set('pageIndex', '0');
  param.set('pageSize', '0');
  licenseId && param.set('licenseId', licenseId);
  return useSWRGet<TableData<UserDto>>(usersUrl + '?' + param.toString());
};

const usersApi = { fetchUsersTable, useUsersTotal, useUsersHistory, useLicenses };

export default usersApi;
