import React, { FC } from 'react';
import { BasicRuleProduct } from '../../../../../../../api/models/server/rules';
import { ProductMultiselect } from '../../../../../../../components/rules/product-multiselect';
import { FormikErrors } from 'formik';
import { FormikRuleData } from '../index';

import styles from './popup-pages.module.scss';

export const LimitProducts: FC<{
  list: BasicRuleProduct[];
  setList: (value: BasicRuleProduct[]) => void;
  errors: FormikErrors<FormikRuleData>;
}> = ({ list, setList, errors }) => {
  return (
    <>
      <h3 className="wt-h3 wt-offset-top-24">
        Choose <span className={styles.highlighted}>which licenses</span> these users will have access to
      </h3>
      <ProductMultiselect multiselectError={errors.products as string} className="wt-offset-top-24" list={list} setList={setList} />
    </>
  );
};
