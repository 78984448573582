import React, { FC } from 'react';
import { FileIcon } from '@webteam/icons';
import { Col, Row } from '@webteam/layout';
import LinkButton from '../../common/link-button';

import styles from './export-button.module.scss';

export const ExportButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <>
      <Row size="xs" alignItems="center" className={styles.exportButton}>
        <Col span="inline">
          <FileIcon className={styles.exportIcon} />
        </Col>
        <Col span="inline">
          <LinkButton className="wt-text-2 wt-text-2_hardness_primary" onClick={() => onClick()}>
            Export
          </LinkButton>
        </Col>
      </Row>
    </>
  );
};
