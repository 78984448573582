import useSWR, { SWRConfiguration } from 'swr';
import { client } from '../../shared-interface';
import { Problem } from '../models/problem';

const getFetcher = (url: string, rawParams?: string) => {
  const params = rawParams ? JSON.parse(rawParams) : undefined;
  return client().get(url, { params }).then(({ data }) => data);
};

export const useSWRGet = <Data>(url: string | null, swrConfig: SWRConfiguration | undefined = undefined) => {
  return useSWR<Data, Problem>(url, getFetcher, swrConfig);
};
