import { useSWRGet } from '../../swr';
import { LicenseRevocationData, LicensesType, LicenseUsageStatPeriod, LicenseWithStatsDto, TimedUsage } from '../../models/licenses';
import { client } from '../../../shared-interface';
import { LicenseTicketDto } from '../../models/users';

export const licensesUrl = '/licenses';
export const chartUrl = (licenseId: string) => `${licensesUrl}/${licenseId}/stats`;

const describeRevokesUrl = `${licensesUrl}/tickets/revoke/describe`;

const useLicenses = (licensesType: LicensesType) => {
  const param = new URLSearchParams();
  param.set('licensesType', licensesType);
  return useSWRGet<LicenseWithStatsDto[]>(`${licensesUrl}?${param.toString()}`);
};

const useChart = (licenseId: string, period: LicenseUsageStatPeriod) => {
  const param = new URLSearchParams();
  param.set('period', period);
  return useSWRGet<TimedUsage[]>(`${chartUrl(licenseId)}?${param.toString()}`);
};

const revokeLicenses = (ids: string[]) => client().post(`${licensesUrl}/tickets/revoke`, ids);

const useDescribeRevokes = (licenseTickets: Array<LicenseTicketDto>) => {
  const param = new URLSearchParams();
  licenseTickets.forEach((ticket) => {
    param.append('id', ticket.id!!);
  });
  return useSWRGet<LicenseRevocationData>(`${describeRevokesUrl}?${param.toString()}`);
};

const licensesApi = {
  useChart,
  useLicenses,
  useDescribeRevokes,
  revokeLicenses,
};

export default licensesApi;
