import React, { FC, useState } from 'react';
import dayjs from 'dayjs';
import classnames from 'classnames';
import { Col, Container, Row } from '@webteam/layout';
import { DownIcon, PersonIcon, SuccessIcon, UpIcon } from '@webteam/icons';
import { List, ListItem } from '@webteam/list';
import { BADDropdown, BADItemWithIcon } from '@bad/components';
import { ReactComponent as ExitIcon } from '../../../resources/portal-page/exit-icon.svg';
import api from '../../../api/routes';
import {getTimezone, isEmbedded} from '../../../common';

import globalStyles from './portal-page.module.scss';

export const TopBar: FC<{
  isAdmin: boolean;
  projectName?: string;
  id?: string;
  date?: Date;
  username: string;
}> = ({ isAdmin, projectName, id, date, username }) => {
  return (
    <Container className={globalStyles.largeSizeContainer}>
      <Row alignItems="center" className={globalStyles.topBarRow}>
        <Col span="inline" className="wt-offset-top-12">
          {isAdmin && (
            <>
              <Row>
                <Col className="wt-text-3 wt-text-3_theme_dark wt-text-3_hardness_pale" span="inline">
                  ID <span className="wt-text-3_theme_dark wt-text-3_hardness_average">{id}</span>
                </Col>
              </Row>
              <Row>
                <Col className="wt-text-1 wt-text-1_theme_dark wt-text-1_hardness_hard" span="inline">
                  <div className={globalStyles.topBarLabel} title={projectName}>
                    {projectName}
                  </div>
                </Col>
              </Row>
            </>
          )}
        </Col>

        <Col span="auto-fill" className={classnames(globalStyles.lastConnectedInfo, 'wt-offset-top-12 wt-col-md-12')}>
          {isAdmin && (
            <BADItemWithIcon icon={<SuccessIcon className={globalStyles.successIcon} />} iconPosition="left">
              <Row className="wt-text-3">
                <Col className="wt-text-3_theme_dark wt-text-3_hardness_pale" span={12}>
                  Last connected to JetBrains
                </Col>
                <Col className="wt-text-3_theme_dark wt-text-3_hardness_average" span={12}>
                  {dayjs(date).format('MMMM D, YYYY H.mm')} GMT {getTimezone()}
                </Col>
              </Row>
            </BADItemWithIcon>
          )}
        </Col>

        <Col span="inline" className={classnames(!isAdmin && 'wt-offset-top-md-0', 'wt-offset-top-12 wt-col-md-12')}>
          <SignOutDropdown username={username} />
        </Col>
      </Row>
    </Container>
  );
};

const SignOutDropdown: FC<{ username: string }> = ({ username }) => {
  const [ isOpen, toggleOpen ] = useState(false);
  const DropdownIcon = isOpen ? UpIcon : DownIcon;
  const Trigger = (
    <div className={globalStyles.logOutDropdown} onClick={() => toggleOpen(!isOpen)}>
      <div>
        <PersonIcon className={globalStyles.dropdownIcon} />
      </div>

      <div className={classnames('wt-text-2 wt-text-2_theme_dark wt-text-2_hardness_average', globalStyles.topBarLabel, globalStyles.username)} title={username}>
        {username}
      </div>

      <div>
        <DropdownIcon className={globalStyles.dropdownIcon} />
      </div>
    </div>
  );

  return (
    <BADDropdown
      placement="bottom-end"
      size="xs"
      onRequestClose={() => toggleOpen(false)}
      trigger={Trigger}
      isOpen={isOpen}
    >
      <List>
        {!isEmbedded() && (
          <ListItem icon={<ExitIcon />} onClick={api.sessions.useLogout}>
            Sign out
          </ListItem>
        )}
        {isEmbedded() && (
          <ListItem onClick={() => window.location.pathname = '/'}>
            Go to Toolbox Enterprise
          </ListItem>
        )}
      </List>
    </BADDropdown>
  );
};
