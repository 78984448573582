import { routes, client } from '../../../shared-interface';
import { LocalStorageKeys } from '../../models/localstorage-keys';

export const useLogout = () => {
  client().get(routes().signup.logout).then((response) => {
    localStorage.setItem(LocalStorageKeys.showSignInPage, 'true');
    window.location.href = response.request.responseURL;
  });
};

export const wasLoggedOut = () => localStorage.getItem(LocalStorageKeys.showSignInPage) === 'true';

export const isSignInPage = () => window.location.pathname === routes().signup.sign_in;
