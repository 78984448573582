import { client } from '../../../shared-interface';

export const reportUrl = '/report/export';
export const usedLicenseUrl = `${reportUrl}/usedLicenses`;
export const denialsReportUrl = `${reportUrl}/denials`;

const exportUsedLicenseReport = (from: string, to: string, licenseId?: string) => {
  let urlSearchParams = new URLSearchParams();
  urlSearchParams.append('from', from);
  urlSearchParams.append('to', to);
  licenseId && urlSearchParams.append('licenseCode', licenseId)
  return client().get(`${usedLicenseUrl}?${urlSearchParams.toString()}`);
};

const exportDenialsReport = (from: string, to: string | null) => {
  let urlSearchParams = new URLSearchParams();
  urlSearchParams.append('from', from);
  if (to != null) {
    urlSearchParams.append('to', to);
  }
  return client().get(`${denialsReportUrl}?${urlSearchParams.toString()}`);
};

const reportApi = {
  exportUsedLicenseReport,
  exportDenialsReport,
};

export default reportApi;
