import classNames from 'classnames';
import styles from './signin-page.module.scss';
import { BADButton } from '@bad/components';
import { routes } from '../../shared-interface';
import { useNavigate } from 'react-router-dom';
import { LocalStorageKeys } from '../../api/models/localstorage-keys';

export const SignInPage = () => {
  const navigate = useNavigate();

  return (
    <div className="wt-offset-top-96">
      <h3 className={classNames('wt-h3', styles.title)}>You have successfully signed out from your License Vault</h3>
      <BADButton
        onClick={() => {
          localStorage.removeItem(LocalStorageKeys.showSignInPage);
          window.location.href = routes().signup.login;
          navigate(routes().signup.login);
        }}
      >
        Sign In Again
      </BADButton>
    </div>
  );
};
