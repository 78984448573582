import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { Col, Row } from '@webteam/layout';
import { ReactComponent as ReloadIcon } from '../../../resources/dashboard/reload-icon.svg';
import LinkButton from '../../common/link-button';
import api from '../../../api/routes';

import styles from './call-home-button.module.scss';

export const CallHomeButton: FC<{ revalidate: () => Promise<void> }> = ({ revalidate }) => {
  const [loading, setLoading] = useState(false);
  const handleOnClick = () => {
    setLoading(true);
    api.server.callHome().then(() => {
      setTimeout(
        () =>
          revalidate().finally(() => {
            setLoading(false);
          }),
        2000,
      );
    });
  };
  return (
    <Row size="xs" alignItems="center" className={classNames(styles.callHomeButton, loading && styles.rotateAnimation)}>
      <Col span="inline">
        <ReloadIcon />
      </Col>
      <Col span="inline">
        <LinkButton className="wt-text-2 wt-text-2_hardness_primary" onClick={handleOnClick}>
          Reload
        </LinkButton>
      </Col>
    </Row>
  );
};
