export interface TicketDenialEventDto {
  username: string;
  timestamp?: Date;
  productDisplayName: string;
  productVersion: string;
  denialReason: TicketDenialReason;
  ip?: string;
  hostname?: string;
}

export enum TicketDenialReason {
  CANCELLED = 'CANCELLED',
  FORBIDDEN = 'FORBIDDEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
}
