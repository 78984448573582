import { useSWRGet } from '../../../../swr';
import { client } from '../../../../../shared-interface';
import { rulesUrl } from '../index';
import { BasicPriorities, Priorities } from '../../../../models/server/rules/priorities';

const prioritiesUrl = '/priorities';

const usePriorities = () => useSWRGet<Priorities[]>(rulesUrl + prioritiesUrl);

const deletePriorities = (id: string) => client().delete(`${rulesUrl + prioritiesUrl}/${id}`);

const updatePriorities = (added?: BasicPriorities[], deleted?: Priorities[]) =>
  client().put(rulesUrl + prioritiesUrl, {
    added: added ?? [],
    deleted: deleted ?? [],
  });

export const priorities = {
  usePriorities,
  deletePriorities,
  updatePriorities,
};
