import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { getRoutingSchema, routes, RoutingSchemaItem } from '../shared-interface';
import { PortalPage } from '../components/common/portal-page';
import { ErrorPage } from '../components/common/error-page';
import { AccessLevel, LicenseServerMode } from '../api/models/server';
import { CommonErrorType } from '../api/models/errors';
import { ModeContext } from '../context';

const PortalSwitch = () => {
  const getPageContent = (access: AccessLevel) => {
    const routingSchema = getRoutingSchema(access);
    return routingSchema ? <RoutesSwitch routingSchema={routingSchema} /> : <ErrorPage message={CommonErrorType.PermissionDenied} />;
  };

  return <PortalPage>{(access: AccessLevel, mode: LicenseServerMode) => <ModeContext.Provider value={mode}>{getPageContent(access)}</ModeContext.Provider>}</PortalPage>;
};

const nestedRoutes = (routingSchema?: RoutingSchemaItem[]) => {
  if (!routingSchema) return null;
  return routingSchema.map(({ path, component: PageComponent, key, children }) => {
    return (
      <Route path={path} key={key} element={<PageComponent />}>
        {nestedRoutes(children)}
      </Route>
    );
  });
};

const RoutesSwitch = ({ routingSchema }: { routingSchema: RoutingSchemaItem[] }) => (
  <Routes>
    {nestedRoutes(routingSchema)}
    <Route path="*" element={<Navigate to={routes().admin.dashboard.tabs.products} />} />
  </Routes>
);

export default PortalSwitch;
