import { useSWRGet } from '../../../swr';
import { AccessRuleDto, BaseAccessRuleDto, BasicRuleProduct, BasicRuleSubject, RuleSettings, TestRuleData, TestRuleResult, UpdateAccessRuleDto } from '../../../models/server/rules';
import { client } from '../../../../shared-interface';
import { priorities } from './priorities';

export const rulesUrl = '/rules';
const subjectsUrl = '/subjects/search';
const productsUrl = '/products';
const settingsUrl = '/settings';
const testUrl = '/test';

const useSubject = (search: string) => {
  const param = new URLSearchParams();
  param.set('query', search);
  return useSWRGet<BasicRuleSubject[]>(search ? `${rulesUrl}${subjectsUrl}?${param.toString()}` : null);
};

const useProduct = () => useSWRGet<BasicRuleProduct[]>(rulesUrl + productsUrl);

const useTestProduct = () => useSWRGet<BasicRuleProduct[]>(rulesUrl + testUrl + productsUrl);

const useRuleSettings = () => useSWRGet<RuleSettings>(rulesUrl + settingsUrl);

export const setRuleSettings = (settings: RuleSettings) => client().patch(rulesUrl + settingsUrl, settings);

const useRules = () => useSWRGet<AccessRuleDto[]>(rulesUrl);

export const addRule = (rule: BaseAccessRuleDto<BasicRuleSubject, BasicRuleProduct>) => client().post(rulesUrl, rule);

export const deleteRule = (id: number) => client().delete(`${rulesUrl}/${id}`);

export const updateRule = (id: number, rule: UpdateAccessRuleDto) => client().patch(`${rulesUrl}/${id}`, rule);

export const testRule = (data: TestRuleData) => client().post<TestRuleResult>(rulesUrl + testUrl, data);

const rulesApi = {
  priorities,
  useProduct,
  useTestProduct,
  setRuleSettings,
  useRuleSettings,
  useSubject,
  useRules,
  updateRule,
  addRule,
  deleteRule,
  testRule,
};

export default rulesApi;
