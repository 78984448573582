import React, { FC, useMemo } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Col, Row } from '@webteam/layout';
import { RouterTabList, Tab } from '../../../components/react-router-tabs/react-router-tabs';
import { BreadCrumb } from '@bad/components/dist/breadcrumbs';
import { ContentSkeleton } from '../../../components/common/skeleton/content-skeleton';
import { countTotal } from './tabs/versions-stats/table';
import { routes } from '../../../shared-interface';
import api from '../../../api/routes';
import { LicensesType } from '../../../api/models/licenses';

import globalStyle from '../dashboard.module.scss';
import { StyledBreadcrumbs } from '../../../components/common/breadcrumbs';
import { pluralize } from '../../../common';

export const LicensesInfoPage = () => {
  const homepage = process.env.REACT_APP_HOME_PAGE!!;
  const { licensesType, licenseId } = useParams() as { licenseId: string; licensesType: LicensesType };

  const licenses = api.licenses.useLicenses(licensesType);
  const usersTotal = api.users.useUsersTotal(licenseId);

  const license = useMemo(() => licenses.data?.find((el) => el.id === licenseId), [licenses.data, licenseId]);

  const breadcrumbsItem: BreadCrumb[] = [
    ['Dashboard', routes().admin.dashboard.tabs.products],
    [license?.name ?? '', window.location.pathname.replace(homepage, '') + window.location.search],
  ];

  const loading = (!licenses.data && !licenses.error) || (!usersTotal.data && !usersTotal.error);
  if (loading) return <ContentSkeleton />;

  return (
    <>
      <Row className="wt-offset-top-12">
        <Col span="auto-fill">
          <StyledBreadcrumbs items={breadcrumbsItem} isReactRouterLink={true} />
        </Col>
      </Row>

      <Row className="wt-offset-top-24" alignItems="center">
        <Col span="auto-fill">
          <Header name={license?.name ?? 'License'} total={license ? countTotal(license.usage, 'available') : 0} />
        </Col>
        <Col span="inline">
          {/*TODO: add action*/}
          {/*<BADButton onClick={() => {}}>Manage Licenses</BADButton>*/}
        </Col>
      </Row>

      <Row>
        <Col span="auto-fill">
          <RouterTabList>
            <Tab className={globalStyle.tab} value={routes().admin.licenses.tabs.versions({ licenseId, licensesType })}>
              Stats <sup className={globalStyle.sup} />
            </Tab>
            <Tab className={globalStyle.tab} value={routes().admin.licenses.tabs.users({ licenseId, licensesType })}>
              Active Users <sup className={globalStyle.sup}>{usersTotal.data?.total}</sup>
            </Tab>
          </RouterTabList>
          <Outlet />
        </Col>
      </Row>
    </>
  );
};

const Header: FC<{ total: number; name: string }> = ({ total, name }) => {
  return (
    <h3 className="wt-h3 title">
      {total > 0 ? total : 'No'} {name} {pluralize(total, 'license')}
    </h3>
  );
};
