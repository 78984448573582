import React from 'react';
import classNames from 'classnames';
import { Col, Row } from '@webteam/layout';

import styles from './skeleton.module.scss';
import animationStyles from './animation.module.scss';

const smallProgressBlockClassName = classNames([styles.smallProgressBlock, animationStyles.loadingAnimation, animationStyles.contentLoadingAnimationColor]);

const largeProgressBlockClassName = classNames([styles.largeProgressBlock, animationStyles.loadingAnimation, animationStyles.contentLoadingAnimationColor]);

export const ContentSkeleton = () => {
  return (
    <>
      <Row className="wt-offset-top-48">
        <Col span={4}>
          <div className={smallProgressBlockClassName} />
          <div className={`${smallProgressBlockClassName} wt-offset-top-48`} />
        </Col>
        <Col span={2} offsetLeft={6} className={smallProgressBlockClassName} />
      </Row>
      {[0, 1, 2, 3].map((el) => (
        <Row className="wt-offset-top-48" key={JSON.stringify(el)}>
          <Col span={12} className={largeProgressBlockClassName} />
        </Row>
      ))}
    </>
  );
};
