import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../../../api/routes';
import { ConfirmationPopup } from '../../../../../components/common/confirmation-popup';
import { RuleSubjectType } from '../../../../../api/models/server/rules';

export const DeletePrioritiesPopup: FC<{ onRequestClose: () => void }> = ({ onRequestClose }) => {
  const { id } = useParams() as { id: string };
  const priorities = api.server.rules.priorities.usePriorities();
  const item = priorities.data?.find((el) => el.id === +id);
  if (item)
    return (
      <ConfirmationPopup
        successAction={priorities.mutate}
        title={`Remove “${item.subject.name}” from the Priorities?`}
        content={<>You can add it back anytime.</>}
        action={() => api.server.rules.priorities.deletePriorities(id)}
        actionButtonName={`Remove ${item.subject.subjectType === RuleSubjectType.Group ? 'Group' : 'User'}`}
        onRequestClose={onRequestClose}
      />
    );
  return <></>;
};
