import React from 'react';
import { TabList } from '@rescui/tab-list';
import { useLocation, useNavigate } from 'react-router-dom';

export { Tab, TabSeparator } from '@rescui/tab-list';

const compareRouterTabValues = (activeValue: any, tabValue: any) => {
  return activeValue.pathname === tabValue;
};

export const RouterTabList = (props: any) => {
  const location = useLocation();
  const navigate = useNavigate();
  return <TabList {...props} value={location} compareValues={compareRouterTabValues} onChange={(value: any) => navigate(value)} />;
};
