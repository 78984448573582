import React, { FC } from 'react';
import { List, ListItem } from '@webteam/list';
import { FolderIcon } from '@webteam/icons';
import { NavLink } from 'react-router-dom';
import { routes } from '../../../shared-interface';

import globalStyles from './portal-page.module.scss';

export const Footer: FC<{ showMenu: boolean }> = ({ showMenu }) => (
  <List theme="dark">
    {showMenu && (
      <NavLink to={routes().admin.currentLicenses.path} className={({ isActive }) => (isActive ? globalStyles.active : undefined)}>
        <ListItem icon={<FolderIcon />}>Your Licenses</ListItem>
      </NavLink>
    )}
    <ListItem disabled={true}>
      <span className="wt-text-3 wt-text-3_theme_dark wt-text-3_hardness_pale">Build #{process.env.REACT_APP_VERSION ?? 'UNKNOWN'}</span>
    </ListItem>
  </List>
);
