import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import { Cell } from 'react-table';
import { BADTable } from '@bad/components';
import { LicenseUsageDto, LicenseWithStatsDto, UsageDto } from '../../../../../api/models/licenses';

import styles from './versions-stats-tab.module.scss';

export const countTotal = (data: LicenseUsageDto, prop: 'available' | 'used') => {
  let result = 0;
  const stats = [data.regular, data.trueUp, data.virtual].filter(Boolean);
  stats.forEach((el) => {
    result = result + el!![prop];
  });
  return result;
};

export const VersionsStatsTable: FC<{ data: LicenseWithStatsDto }> = ({ data }) => {
  const numberCellClassName = (el: number, isBold: boolean = true, isRatio: boolean = false) =>
    classNames([styles.numberCell, isBold && 'wt-h3', isRatio && el === 100 && styles.fullNumberCell, el === 0 && styles.emptyNumberCell]);

  const tableData = useMemo(() => {
    const filteredData = [
      { perVersion: false, type: `Regular`, ...data.usage.regular },
      { perVersion: false, type: `True-up`, ...data.usage.trueUp },
      { perVersion: false, type: `Virtual`, ...data.usage.virtual },
    ].filter((el) => Object.keys(el).includes('available'));
    if (filteredData.length > 1) {
      filteredData.forEach((item) => (item.perVersion = true));
      filteredData.unshift({
        perVersion: false,
        type: 'All',
        available: countTotal(data.usage, 'available'),
        used: countTotal(data.usage, 'used'),
      });
    }
    return filteredData;
  }, [data]);

  return (
    <BADTable
      className={classNames(styles.hideTableBorder, styles.firstTableTrOffset, 'wt-text-3')}
      size="s"
      offsetTableSize="s"
      wide={true}
      tableData={tableData}
      columnsFn={() => [
        {
          id: 'type',
          accessor: 'type',
          Header: 'License type',
          Cell: (props: Cell<UsageDto & { perVersion: boolean }>) => <span className={classNames(!props.row.original.perVersion && 'wt-h3')}>{props.value}</span>,
        },
        {
          id: 'available',
          accessor: 'available',
          Header: <div className={styles.numberCell}>Added licenses</div>,
          Cell: (props: Cell<UsageDto & { perVersion: boolean }>) => <span className={numberCellClassName(props.value, !props.row.original.perVersion)}>{props.value}</span>,
        },
        {
          id: 'used',
          accessor: 'used',
          Header: <div className={styles.numberCell}>Used licenses</div>,
          Cell: (props: Cell<UsageDto & { perVersion: boolean }>) => <span className={numberCellClassName(props.value, !props.row.original.perVersion)}>{props.value}</span>,
        },
        {
          id: 'ratio',
          Header: <div className={styles.numberCell}>Usage ratio</div>,
          Cell: (props: Cell<UsageDto & { perVersion: boolean }>) => {
            let value = Math.round((props.row.original.used / props.row.original.available) * 100) ?? 0;
            if (isNaN(value) || !isFinite(value)) value = 0;
            return <span className={numberCellClassName(value, !props.row.original.perVersion, true)}>{value}%</span>;
          },
        },
      ]}
    />
  );
};
