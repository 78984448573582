import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './error-page.module.scss';

export const ErrorPage = ({ message, detail, containerClass = 'wt-col_align-self_center' }: { message?: string; detail?: ReactNode; containerClass?: string }) => (
  <div className={`wt-offset-top-32 ${containerClass}`}>
    <h3 className={classNames('wt-h3', styles.title)}>{message || 'Unknown error'}</h3>
    <p className="wt-offset-top-24 wt-text-1 wt-text-1_hardness_average">{detail || 'An unexpected error has occurred.'}</p>
    <p className="wt-offset-top-16 wt-text-1 wt-text-1_hardness_pale">
      Please try again or{' '}
      <a className="wt-link" href={process.env.REACT_APP_SUPPORT_LINK ?? 'mailto:license-vault-support@jetbrains.com'}>
        contact us
      </a>{' '}
      for assistance.
    </p>
  </div>
);
